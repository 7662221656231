import {Injectable} from '@angular/core';

@Injectable()
export class Globals {
    readonly DEFAULT_LANGUAGE_ID: number = 2;
    readonly DECIMAL_QUANTITY_PATTERN: string = '^[0-9]+(\.[0-9]{1,3})?$';
    readonly INTEGER_PATTERN: string = '^[0-9]+$';
    readonly INTEGER_QUANTITY_PATTERN: string = '^[0-9]+$';
    readonly MOBILE_CODE_PATTERN: string = '^[0-9\-]+$';
    readonly NO_SPACES_ONLY: string = '(?!^ +$)^.+$';
    readonly FISCAL_CODE: string = '^[A-Z0-9]{16}$';
    readonly DATE_LITTLE_ENDIAN: string = '^(((0[1-9]|[12][0-9]|3[01])[- /.](0[13578]|1[02])|(0[1-9]|[12][0-9]|30)[- /.](0[469]|11)|(0[1-9]|1\\d|2[0-8])[- /.]02)[- /.]\\d{4}|29[- /.]02[- /.](\\d{2}(0[48]|[2468][048]|[13579][26])|([02468][048]|[1359][26])00))$';
    readonly DOC_SPECIFICHE: string = "Specifiche";
    readonly DOC_MICROGRAFIA: string = "Micrografia";
    readonly DOC_CAPABILITY_ALTEZZA: string = "CapabilityAltezza";
    readonly DOC_CAPABILITY_FORZA: string = "CapabilityForza";
    readonly DOC_MANUALE: string = "Manuale";
    readonly DOC_ORDER_FORM: string = "OrderForm";
    readonly DOC_MICROGRAFIE_INTERNE: string = "MicrografieInterne";
    readonly DOC_MICROGRAFIE_CLIENTE: string = "MicrografieCliente";
    readonly DOC_CERTIFICATO_COLLAUDO: string = "CertificatoCollaudo";
    readonly DOC_DISEGNO: string = "Disegno";
    readonly DEFAULT_LT: number = 18.95;
    readonly DEFAULT_HA: number = 63.7;
    readonly DEFAULT_HB: number = 53.35;
    readonly DEFAULT_HT: number = 73.5;
    readonly DEFAULT_FF: number = 9;
    readonly DEFAULT_HTOT: number = 135.8;
    readonly DEFAULT_HG: number = 18.5;
    readonly AWG_VALUES = new Map<string, string>([
        ["0.03", "32"],
        ["0.05", "30"],
        ["0.0646", "29"],
        ["0.08", "28"],
        ["102", "27"],
        ["0.12", "26"],
        ["0.16", "25"],
        ["0.2", "24"],
        ["259", "23"],
        ["0.33", "22"],
        ["412", "21"],
        ["0.5", "20"],
        ["653", "19"],
        ["0.75", "18"],
        ["1.04", "17"],
        ["1.25", "16"],
        ["1.65", "15"],
        ["2", "14"],
        ["2.63", "13"],
        ["3.31", "12"],
        ["4.15", "11"],
        ["5.27", "10"],
        ["6.62", "9"],
        ["8.35", "8"],
        ["10.6", "7"],
        ["13.3", "6"],
        ["16.8", "5"],
        ["21.2", "4"],
        ["26.7", "3"],
        ["33.6", "2"],
        ["42.4", "1"],
        ["53.4", "0"],
        ["67.5", "2/0"],
        ["85", "3/0"],
        ["107", "4/0"],
        ["135.1", "5/0"],
        ["170.3", "6/0"],
    ]);
}
