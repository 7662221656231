import {AbstractCommand} from './abstract-command';

export class PosizioneGhieraCommand extends AbstractCommand {
    indice: number;
    posizione: string;
    sezione: number;
    wh: number;
    ih: number;
    whNominale: number;
    ihNominale: number;
    trazioneKg: number;
    trazioneN: number;
}
