import {AbstractCommand} from './abstract-command';
import {TipoComponente} from '../tipo-componente';

export class ComponenteCommand extends AbstractCommand {
    tipoComponente: TipoComponente;
    nome: string;
    descrizione: string;
    tipoFigura: string;
    numeroTrefoli: number;
    diametroTrefolo: number;
    larghezzaTerminale: number;
    spessoreTerminale: number;
    sezioneFilo: number;
    csa: number;
    spessore: number;
    passoAlette: number;
    lp: number;
    lt: number;
    lt2: number;
    ff: number;
    ha: number;
    hb: number;
    ht: number;
    h: number;
    sp: number;
    p: number;
    s: number;
    r: number;
    lf: number;
    pi: number;
    c: number;
    ri: number;
    hli: number;
    li: number;
    htot: number;
    hg: number;

    constructor(
        tipoComponente: TipoComponente,
        nome: string,
        descrizione: string,
        tipoFigura: string,
        numeroTrefoli: number,
        diametroTrefolo: number,
        larghezzaTerminale: number,
        spessoreTerminale: number,
        sezioneFilo: number,
        csa: number,
        spessore: number,
        passoAlette: number,
        lp: number,
        lt: number,
        lt2: number,
        ff: number,
        ha: number,
        hb: number,
        ht: number,
        h: number,
        sp: number,
        p: number,
        s: number,
        r: number,
        lf: number,
        pi: number,
        c: number,
        ri: number,
        hli: number,
        li: number,
        htot: number,
        hg: number
    ) {
        super();
        this.tipoComponente = tipoComponente;
        this.nome = nome;
        this.descrizione = descrizione;
        this.tipoFigura = tipoFigura;
        this.numeroTrefoli = numeroTrefoli;
        this.diametroTrefolo = diametroTrefolo;
        this.larghezzaTerminale = larghezzaTerminale;
        this.spessoreTerminale = spessoreTerminale;
        this.sezioneFilo = sezioneFilo;
        this.csa = csa;
        this.spessore = spessore;
        this.passoAlette = passoAlette;
        this.lp = lp;
        this.lt = lt;
        this.lt2 = lt2;
        this.ff = ff;
        this.ha = ha;
        this.hb = hb;
        this.ht = ht;
        this.h = h;
        this.sp = sp;
        this.p = p;
        this.s = s;
        this.r = r;
        this.lf = lf;
        this.pi = pi;
        this.c = c;
        this.ri = ri;
        this.hli = hli;
        this.li = li;
        this.htot = htot;
        this.hg = hg;
    }
}
