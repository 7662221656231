import {Component, OnInit} from '@angular/core';
import {ClrDatagridStateInterface} from '@clr/angular';
import {QueryResult} from '../../../model/query/query-result';
import {Observable, forkJoin, of} from 'rxjs';
import {HttpResponse} from '@angular/common/http';
import {ClrLoadingState} from '@clr/angular';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Globals} from '../../../core/globals.service';
import {BaseService} from '../../../core/base.service';
import {StatoOrdine} from '../../../model/stato-ordine';
import {AuthService} from '../../../core/auth.service';
import {OrdiniService} from '../../../core/ordini.service';
import {TestService} from '../../../core/test.service';
import {ClientiService} from '../../../core/clienti.service';
import {ApplicatoriService} from '../../../core/applicatori.service';
import {TerminaliService} from '../../../core/terminali.service';
import {UtilsService} from '../../../core/utils.service';
import {AbstractBean} from '../../../model/query/abstract-bean';
import {Ordine} from '../../../model/query/ordine';
import {Cliente} from '../../../model/query/cliente';
import {ClienteDTO} from '../../../model/command/cliente-dto';
import {ClienteCommand} from '../../../model/command/cliente-command';
import {Applicatore} from '../../../model/query/applicatore';
import {ApplicatoreCommand} from '../../../model/command/applicatore-command';
import {Terminale} from '../../../model/query/terminale';
import {Test} from '../../../model/query/test';
import {PosizioneGhiera} from '../../../model/query/posizione-ghiera';
import {ClienteCodiceTerminaleCommand} from '../../../model/command/cliente-codice-terminale-command';
import {TestCommand} from '../../../model/command/test-command';
import {PosizioneGhieraCommand} from '../../../model/command/posizione-ghiera-command';
import {EtichettaDTO} from '../../../model/command/etichetta-dto';
import {OrdineCommand} from '../../../model/command/ordine-command';
import {OrdineDTO} from '../../../model/command/ordine-dto';
import {Action} from '../../../model/action';
import {DialogService} from '../../dialog';

@Component({
    selector: 'app-ordine-detail',
    templateUrl: './ordine-detail.component.html',
    styleUrls: ['./ordine-detail.component.css']
})
export class OrdineDetailComponent implements OnInit {
    readonly STATO_ORDINE: typeof StatoOrdine = StatoOrdine;
    readonly ALTEZZA_STANDARD: number = 135.8;
    readonly CORSA_STANDARD: number = 40;
    
    puntiTrascinamentoDescription: any;
    statiOrdineDescription: any;
    modelliApplicatoreDescription: any;
    tipiApplicatoreDescription: any;
    specificheAggraffaturaDescription: any;
    tipoDisposizioneDescription: any;
    tipoAggraffaturaDescription: any;
    materialeOrdineDescription: any;
    submitButton: ClrLoadingState = ClrLoadingState.DEFAULT;
    form: FormGroup;
    selectedObjects = [];
    id: string;
    alertClosed = true;
    alertMessage: String;
    action: Action;
    readOnly = false;
    ordine: Ordine;
    clienti: Array<Cliente>;
    totalClienti: number;
    selectedCliente: Cliente;
    selectedApplicatore: Applicatore;
    selectedTerminale: Terminale;
    showSceltaClienteModal: boolean = false;
    file: File;
    toDelete: Array<Number> = new Array<Number>();
    showDialogCapabilityAltezza = false;
    showDialogCapabilityForza = false;
    etichetta: boolean = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        public authService: AuthService,
        private ordiniService: OrdiniService,
        private testService: TestService,
        private clientiService: ClientiService,
        private applicatoriService: ApplicatoriService,
        private terminaliService: TerminaliService,
        private utilsService: UtilsService,
        private dialog: DialogService,
        private formBuilder: FormBuilder,
        private globals: Globals
    ) {
        this.puntiTrascinamentoDescription = utilsService.getPuntiTrascinamentoDescription();
        this.statiOrdineDescription = utilsService.getStatiOrdineDescription();
        this.modelliApplicatoreDescription = utilsService.getModelliApplicatoreDescription();
        this.tipiApplicatoreDescription = utilsService.getTipiApplicatoreDescription();
        this.specificheAggraffaturaDescription = utilsService.getSpecificheAggraffaturaDescription();
        this.tipoDisposizioneDescription = utilsService.getTipoDisposizioneDescription();
        this.tipoAggraffaturaDescription = utilsService.getTipoAggraffaturaDescription();
        this.materialeOrdineDescription = utilsService.getMaterialeOrdineDescription();
    }

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');
        this.createForm();
        this.route.queryParams.subscribe(params => {
            if (params) {
                if (params.action) {
                    this.action = params.action;
                }
                
                let observables: Array<Observable<any>> = [];

                if (params.clienteId) {
                    observables.push(this.clientiService.get(params.clienteId));
                } else {
                    observables.push(of(null));
                }
                if (params.applicatoreId) {
                    observables.push(this.applicatoriService.get(params.applicatoreId));
                } else {
                    observables.push(of(null));
                }
                forkJoin(observables).subscribe(([cliente, applicatore]) => {
                    if (params.clienteId) {
                        this.selectedCliente = cliente;
                    }
                    if (params.applicatoreId) {
                        this.selectedApplicatore = applicatore;
                        this.selectedTerminale = this.selectedApplicatore.terminale;
                    }
                    this.initialize(params.replica);
                });
            }
        });
    }

    initialize(replica: boolean) {
        if (this.id !== 'new') {
            this.ordiniService.get(this.id).subscribe(ordine => {
                this.ordine = ordine;
                this.selectedApplicatore = this.ordine.applicatore;
                this.selectedCliente = this.ordine.cliente;
                this.selectedTerminale = this.selectedApplicatore.terminale;
                ordine.test.forEach(t => this.addTestField());
                this.applicatoriService.getPosizioniGhiera(this.ordine.applicatore.id.toString()).subscribe(result => {
                    for (let i = 0; i < 4; ++i) {
                        this.addPosizioneGhieraField();
                    }
                    this.initializeFormFields(ordine, undefined, result);
                    if (this.action != null && this.action == Action.VIEW) {
                        this.readOnly = true;
                    }
                });
            });
        } else {
            this.initializeFormFields(null, replica, null);
        }
    }
    
    onSubmit() {
        this.submitButton = ClrLoadingState.LOADING;
        
        const formValue = this.form.getRawValue();
        
        this.saveCodiceClienteTerminale(this.selectedTerminale.id, this.selectedCliente.id, formValue.codiceInternoCliente).subscribe(id => {
            if (this.file) {
                this.uploadFileSpecifiche(this.selectedApplicatore.id).subscribe(() => {
                    this.saveOrdine(this.selectedCliente.id, this.selectedApplicatore.id);
                });
            } else {
                this.saveOrdine(this.selectedCliente.id, this.selectedApplicatore.id);
            }
        });
    }
    
    saveCodiceClienteTerminale(terminaleId: number, clienteId: number, codice: string): Observable<number> {
        if (codice) {
            return this.terminaliService.addClienteCodiceTerminale(new ClienteCodiceTerminaleCommand(codice, terminaleId, clienteId));
        } else {
            return of(0);
        }
    }
    
    uploadFileSpecifiche(applicatoreId: number): Observable<number> {
        let fileName = "Specifiche-" + applicatoreId;
        
        return this.applicatoriService.aggiungiDocumento(applicatoreId.toString(), this.globals.DOC_SPECIFICHE, this.file, fileName);
    }

    saveOrdine(clienteId: number, applicatoreId: number): void {
        this.submitButton = ClrLoadingState.LOADING;
        const formValue = this.form.getRawValue();
        const dto: OrdineDTO = new OrdineDTO();
        const command: OrdineCommand = new OrdineCommand(
            formValue.stato,
            clienteId, 
            applicatoreId,
            this.utilsService.fieldToDate(formValue.dataEmissione),
            formValue.numeroOrdine,
            formValue.referente,
            formValue.costruttore,
            formValue.codiceInternoCliente,
            formValue.nuovo,
            formValue.replica,
            formValue.note,
            formValue.materialeOrdine
        );

        dto.ordine = command;
        dto.test = this.createTestCommand();
        dto.toDelete = this.toDelete;
        if (!this.id || this.id === 'new') {
            this.ordiniService.create(dto).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                if (res) {
                    this.alertMessage = 'Ordine creato!';
                    this.alertClosed = false;
                    this.router.navigate(['ordini']);
                }
            });
        } else {
            this.ordiniService.update(this.id, dto).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                if (!res) {
                    this.alertMessage = 'Ordine aggiornato!';
                    this.alertClosed = false;
                    this.router.navigate(['ordini']);
                }
            });
        }
    }

    createTestCommand(): Array<TestCommand> {
        return this.tests.controls.filter(control => control.getRawValue().id == 0).map(control => {
            const fv = control.getRawValue();
            let ic = new TestCommand();
            
            ic.sezioneConduttore = Number(fv.sezioneConduttore);
            ic.diametroIsolante = Number(fv.diametroIsolante);
            ic.micrografia = fv.micrografia;
            ic.capabilityAltezza = fv.capabilityAltezza;
            ic.capabilityForza = fv.capabilityForza;
            return ic;
        });
    }
    
    createPosizioniGhieraCommand(): Array<PosizioneGhieraCommand> {
        return this.posizioniGhiera.controls.map((control, index) => {
            const fv = control.getRawValue();
            let ic = new PosizioneGhieraCommand();
            
            ic.indice = index;
            ic.posizione = fv.posizione;
            ic.sezione = Number(fv.sezione);
            ic.wh = Number(fv.wh);
            ic.ih = Number(fv.ih);
            ic.whNominale = Number(fv.whNominale);
            ic.ihNominale = Number(fv.ihNominale);
            ic.trazioneKg = Number(fv.trazioneKg);
            ic.trazioneN = Number(fv.trazioneN);
            return ic;
        });
    }
    
    salvaDatiEtichetta(datiCollaudo?: boolean): void {
        let dto: EtichettaDTO = new EtichettaDTO();
        const formValue = this.form.getRawValue();
        
        dto.descrizione = formValue.descrizione;
        dto.posizioniGhiera = this.createPosizioniGhieraCommand();
        this.applicatoriService.updateDatiEtichetta(this.ordine.applicatore.id.toString(), dto).subscribe((res) => {
            this.etichetta = true;
            this.updatePosizioniGhiera();
            if (datiCollaudo) {
                let command: ApplicatoreCommand = new ApplicatoreCommand(
                    this.ordine.applicatore.codice,
                    this.ordine.applicatore.terminaleForeignKey,
                    this.ordine.applicatore.modelloApplicatore,
                    this.ordine.applicatore.tipoApplicatore,
                    this.ordine.applicatore.modelloPressa,
                    this.ordine.applicatore.marcaPressa,
                    this.ordine.applicatore.altezzaTaraturaPressa,
                    this.ordine.applicatore.corsaPressa,
                    this.ordine.applicatore.specificheAggraffatura,
                    this.ordine.applicatore.contaCicli,
                    this.ordine.applicatore.ghieraCentesimale,
                    this.ordine.applicatore.taglioBandella,
                    this.ordine.applicatore.aggraffaturaGommino,
                    this.ordine.applicatore.terminaleIncudine,
                    this.ordine.applicatore.disposizioneDoppioCavo,
                    this.ordine.applicatore.aggraffaturaConduttore,
                    this.ordine.applicatore.aggraffaturaIsolante
                );
                
                command.estrattoreFisso = formValue.estrattoreFisso;
                command.estrattoreMobile = formValue.estrattoreMobile;
                command.estrattoreLamella = formValue.estrattoreLamella;
                command.puntoTrascinamento = formValue.puntoTrascinamento;
                command.distanzialeCrimper = formValue.distanzialeCrimper;
                command.distanzialeCrimperSlitta = formValue.distanzialeCrimperSlitta;
                command.distanzialeTrancio = formValue.distanzialeTrancio;
                command.terminaliSufficienti = formValue.terminaliSufficienti;
                command.caviSufficienti = formValue.caviSufficienti;
                command.housing = formValue.housing;
                command.dimensioniConformi = formValue.dimensioniConformi;
                command.compattezzaConforme = formValue.compattezzaConforme;
                command.tenutaConforme = formValue.tenutaConforme;
                command.formaConforme = formValue.formaConforme;
                command.housingConforme = formValue.housingConforme;
                command.note = formValue.note;
                
                this.applicatoriService.updateDatiCollaudo(this.ordine.applicatore.id.toString(), command).subscribe((res) => {
                    this.alertMessage = 'Dati collaudo salvati!';
                    this.alertClosed = false;
                });
            } else {
                this.alertMessage = 'Dati etichetta salvati!';
                this.alertClosed = false;
            }
        });
    }

    createForm(): void {
        const formGroup = {
            dataEmissione: [{value: '', disabled: this.id != 'new'}, [Validators.required]],
            dataEvasione: [{value: '', disabled: true}],
            cliente: [{value: '', disabled: true}],
            stato: [{value: ''}],
            numeroOrdine: [{value: '', disabled: this.id != 'new'}, [Validators.required, Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            referente: [{value: '', disabled: true}],
            terminale: [{value: '', disabled: true}, [Validators.required]],
            costruttore: [{value: '', disabled: true}],
            codiceInternoCliente: [{value: ''}],
            applicatore: [{value: '', disabled: true}],
            nuovo: [{value: true, disabled: true}],
            replica: [{value: false, disabled: true}],
            modello: [{value: '', disabled: true}, [Validators.required]],
            tipo: [{value: '', disabled: true}, [Validators.required]],
            modelloPressa: [{value: '', disabled: true}, [Validators.required, Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            marcaPressa: [{value: '', disabled: true}, [Validators.required, Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            altezzaTaraturaPressa: [{value: '', disabled: true}],
            corsaPressa: [{value: '', disabled: true}],
            specificheAggraffatura: [{value: '', disabled: true}, [Validators.required]],
            contaCicli: [{value: false, disabled: true}],
            ghieraCentesimale: [{value: false, disabled: true}],
            taglioBandella: [{value: false, disabled: true}],
            aggraffaturaGommino: [{value: false, disabled: true}],
            terminaleIncudine: [{value: false, disabled: true}],
            disposizioneDoppioCavo: [{value: '', disabled: true}],
            aggraffaturaConduttore: [{value: '', disabled: true}, [Validators.required]],
            aggraffaturaIsolante: [{value: '', disabled: true}, [Validators.required]],
            estrattoreFisso: [{value: false}],
            estrattoreMobile: [{value: false}],
            estrattoreLamella: [{value: false}],
            puntoTrascinamento: [{value: ''}],
            distanzialeCrimper: [{value: ''}],
            distanzialeCrimperSlitta: [{value: ''}],
            distanzialeTrancio: [{value: ''}],
            terminaliSufficienti: [{value: false}],
            caviSufficienti: [{value: false}],
            housing: [{value: false}],
            dimensioniConformi: [{value: false}],
            compattezzaConforme: [{value: false}],
            tenutaConforme: [{value: false}],
            formaConforme: [{value: false}],
            housingConforme: [{value: false}],
            noteApplicatore: [{value: ''}],
            note: [{ value: '' }],
            materialeOrdine: [{ value: '' }],
            tests: this.formBuilder.array([]),
            posizioniGhiera: this.formBuilder.array([]),
            dataTrasformazione: [{value: '', disabled: true}],
            descrizione: [{value: ''}]
        };
        this.form = this.formBuilder.group(formGroup);
    }

    get tests() {
        return this.form.controls["tests"] as FormArray;
    }
    
    addTestField(): void {
        const item = this.formBuilder.group({
            id: 0,
            sezioneConduttore: ['', Validators.required],
            diametroIsolante: ['', Validators.required],
            micrografia: [false],
            capabilityAltezza: [false],
            capabilityForza: [false],
            fileMicrografiaName: '',
            fileCapabilityAltezzaName: '',
            fileCapabilityForzaName: '',
            fileMicrografia: undefined,
            fileCapabilityAltezza: undefined,
            fileCapabilityForza: undefined
        });
        
        this.tests.push(item);
    }

    addTest(test: Test, tests: Array<any>): void {
        const testValues = {
            id: test.id,
            sezioneConduttore: test.sezioneConduttore,
            diametroIsolante: test.diametroIsolante,
            micrografia: test.micrografia,
            capabilityAltezza: test.capabilityAltezza,
            capabilityForza: test.capabilityForza,
            fileMicrografiaName: test.fileMicrografiaName || '',
            fileCapabilityAltezzaName: test.fileCapabilityAltezzaName || '',
            fileCapabilityForzaName: test.fileCapabilityForzaName || '',
            fileMicrografia: null,
            fileCapabilityAltezza: null,
            fileCapabilityForza: null
        };
        
        tests.push(testValues);
    }

    get posizioniGhiera() {
        return this.form.controls["posizioniGhiera"] as FormArray;
    }
    
    addPosizioneGhieraField(): void {
        const item = this.formBuilder.group({
            posizione: '',
            sezione: '',
            wh: '',
            ih: '',
            whNominale: '',
            ihNominale: '',
            trazioneKg: '',
            trazioneN: ''
        });
        
        this.posizioniGhiera.push(item);
    }

    addPosizioneGhiera(posizione: any, posizioniGhiera: Array<any>): void {
        const posizioniValues = {
            posizione: posizione.posizione || '',
            sezione: posizione.sezione || '',
            wh: posizione.wh || '',
            ih: posizione.ih || '',
            whNominale: posizione.whNominale || '',
            ihNominale: posizione.ihNominale || '',
            trazioneKg: posizione.trazioneKg || '',
            trazioneN: posizione.trazioneN || ''
        };
        
        posizioniGhiera.push(posizioniValues);
    }

    updatePosizioniGhiera(): void {
        this.applicatoriService.getPosizioniGhiera(this.ordine.applicatore.id.toString()).subscribe(result => {
            let values: any = {
                posizioniGhiera: []
            };

            result.forEach(posizione => {
                const posizioniValues = {
                    posizione: posizione.posizione || '',
                    sezione: posizione.sezione || '',
                    wh: posizione.wh || '',
                    ih: posizione.ih || '',
                    whNominale: posizione.whNominale || '',
                    ihNominale: posizione.ihNominale || '',
                    trazioneKg: posizione.trazioneKg || '',
                    trazioneN: posizione.trazioneN || ''
                };
        
                values.posizioniGhiera.push(posizioniValues);
            });
            this.form.patchValue(values);
        });

    }

    initializeFormFields(ordine: Ordine, replica: boolean, posizioniGhiera: Array<PosizioneGhiera>): void {
        let formValues;

        if (ordine) {
            formValues = {
                dataEmissione: this.utilsService.dateToField(ordine.dataEmissione),
                dataEvasione: ordine.dataEvasione ? this.utilsService.dateToField(ordine.dataEvasione) : '',
                cliente: ordine.cliente.ragioneSociale,
                stato: ordine.stato,
                numeroOrdine: ordine.numeroOrdine,
                referente: ordine.cliente.referente ? ordine.cliente.referente : '',
                terminale: ordine.applicatore.terminale.codice,
                costruttore: ordine.applicatore.terminale.costruttore.nome,
                codiceInternoCliente: ordine.codiceInternoCliente,
                applicatore: ordine.applicatore.codice,
                nuovo: ordine.nuovo,
                replica: ordine.replica || false,
                modello: ordine.applicatore.modelloApplicatore,
                tipo: ordine.applicatore.tipoApplicatore,
                modelloPressa: ordine.applicatore.modelloPressa,
                marcaPressa: ordine.applicatore.marcaPressa,
                altezzaTaraturaPressa: ordine.applicatore.altezzaTaraturaPressa,
                corsaPressa: ordine.applicatore.corsaPressa,
                specificheAggraffatura: ordine.applicatore.specificheAggraffatura.toString(),
                contaCicli: ordine.applicatore.contaCicli,
                ghieraCentesimale: ordine.applicatore.ghieraCentesimale,
                taglioBandella: ordine.applicatore.taglioBandella,
                aggraffaturaGommino: ordine.applicatore.aggraffaturaGommino,
                terminaleIncudine: ordine.applicatore.terminaleIncudine,
                disposizioneDoppioCavo: ordine.applicatore.disposizioneDoppioCavo.toString(),
                aggraffaturaConduttore: ordine.applicatore.aggraffaturaConduttore.toString(),
                aggraffaturaIsolante: ordine.applicatore.aggraffaturaIsolante.toString(),
                estrattoreFisso: ordine.applicatore.estrattoreFisso || false,
                estrattoreMobile: ordine.applicatore.estrattoreMobile || false,
                estrattoreLamella: ordine.applicatore.estrattoreLamella || false,
                puntoTrascinamento: ordine.applicatore.puntoTrascinamento?.toString() || '0',
                distanzialeCrimper: ordine.applicatore.distanzialeCrimper || '',
                distanzialeCrimperSlitta: ordine.applicatore.distanzialeCrimperSlitta || '',
                distanzialeTrancio: ordine.applicatore.distanzialeTrancio || '',
                terminaliSufficienti: ordine.applicatore.terminaliSufficienti || false,
                caviSufficienti: ordine.applicatore.caviSufficienti || false,
                housing: ordine.applicatore.housing || false,
                dimensioniConformi: ordine.applicatore.dimensioniConformi || false,
                compattezzaConforme: ordine.applicatore.compattezzaConforme || false,
                tenutaConforme: ordine.applicatore.tenutaConforme || false,
                formaConforme: ordine.applicatore.formaConforme || false,
                housingConforme: ordine.applicatore.housingConforme || false,
                noteApplicatore: ordine.applicatore.note || '',
                note: ordine.note,
                materialeOrdine: ordine.materialeOrdine,
                tests: [],
                posizioniGhiera: [],
                dataTrasformazione: ordine.applicatore.trasformato ? this.utilsService.dateToField(ordine.applicatore.updatedOn) : '',
                descrizione: ordine.applicatore.descrizioneEtichetta || ''
            };
            ordine.test.forEach(t => this.addTest(t, formValues.tests));
            posizioniGhiera.forEach(pos => this.addPosizioneGhiera(pos, formValues.posizioniGhiera));
            if (posizioniGhiera.length == 4) {
                this.etichetta = true;
            } else {
                for (let i = 0; i < 4 - posizioniGhiera.length; ++i) {
                    this.addPosizioneGhiera({}, formValues.posizioniGhiera)
                }
            }
        } else {
            formValues = {
                dataEmissione: this.utilsService.dateToField(new Date()),
                dataEvasione: '',
                cliente: this.selectedCliente?.ragioneSociale || '',
                numeroOrdine: '',
                stato: '0',
                referente: this.selectedCliente?.referente || '',
                terminale: this.selectedApplicatore?.terminale.codice || '',
                costruttore: this.selectedApplicatore?.terminale.costruttore.nome || '',
                codiceInternoCliente: '',
                applicatore: this.selectedApplicatore?.codice || '',
                nuovo: !replica || true,
                replica: replica || false,
                modello: this.selectedApplicatore?.modelloApplicatore || '0',
                tipo: this.selectedApplicatore?.tipoApplicatore || '0',
                modelloPressa: this.selectedApplicatore?.modelloPressa || '',
                marcaPressa: this.selectedApplicatore?.marcaPressa || '',
                altezzaTaraturaPressa: this.selectedApplicatore?.altezzaTaraturaPressa || '',
                corsaPressa: this.selectedApplicatore?.corsaPressa || '',
                specificheAggraffatura: this.selectedApplicatore?.specificheAggraffatura.toString() || '2',
                contaCicli: this.selectedApplicatore?.contaCicli || false,
                ghieraCentesimale: this.selectedApplicatore?.ghieraCentesimale || false,
                taglioBandella: this.selectedApplicatore?.taglioBandella || false,
                aggraffaturaGommino: this.selectedApplicatore?.aggraffaturaGommino || false,
                terminaleIncudine: this.selectedApplicatore?.terminaleIncudine || false,
                disposizioneDoppioCavo: this.selectedApplicatore?.disposizioneDoppioCavo.toString() || '0',
                aggraffaturaConduttore: this.selectedApplicatore?.aggraffaturaConduttore.toString() || '0',
                aggraffaturaIsolante: this.selectedApplicatore?.aggraffaturaIsolante.toString() || '0',
                estrattoreFisso: this.selectedApplicatore?.estrattoreFisso || false,
                estrattoreMobile: this.selectedApplicatore?.estrattoreMobile || false,
                estrattoreLamella: this.selectedApplicatore?.estrattoreLamella || false,
                puntoTrascinamento: this.selectedApplicatore?.puntoTrascinamento?.toString() || '0',
                distanzialeCrimper: this.selectedApplicatore?.distanzialeCrimper || '',
                distanzialeCrimperSlitta: this.selectedApplicatore?.distanzialeCrimperSlitta || '',
                distanzialeTrancio: this.selectedApplicatore?.distanzialeTrancio || '',
                terminaliSufficienti: this.selectedApplicatore?.terminaliSufficienti || false,
                caviSufficienti: this.selectedApplicatore?.caviSufficienti || false,
                housing: this.selectedApplicatore?.housing || false,
                dimensioniConformi: this.selectedApplicatore?.dimensioniConformi || false,
                compattezzaConforme: this.selectedApplicatore?.compattezzaConforme || false,
                tenutaConforme: this.selectedApplicatore?.tenutaConforme || false,
                formaConforme: this.selectedApplicatore?.formaConforme || false,
                housingConforme: this.selectedApplicatore?.housingConforme || false,
                noteApplicatore: this.selectedApplicatore?.note || '',
                note: '',
                materialeOrdine: '0',
                tests: [],
                posizioniGhiera: [],
                dataTrasformazione: '',
                descrizione: ''
            };
        }
        this.form.setValue(formValues);
        if (!ordine) {
            this.setCodiceTerminale();
        }
    }

    onSelectCliente(): void {
        const formValues = {
            cliente: this.selectedCliente ? this.selectedCliente.ragioneSociale : '',
            referente: this.selectedCliente ? this.selectedCliente.referente : ''
        };

        this.form.patchValue(formValues);
        this.setCodiceTerminale();
        this.showSceltaClienteModal = false;
        this.form.markAsDirty();
    }

    setCodiceTerminale(): void {
        if (this.selectedCliente && this.selectedTerminale) {
            this.clientiService.getCodiceTerminale(this.selectedCliente.id.toString(), this.selectedTerminale.id.toString()).subscribe(ct => {
                if (ct) {
                    const formValues = {
                        codiceInternoCliente: ct.codice
                    };

                    this.form.patchValue(formValues);
                }
            });
        }
    }
    
    onShowSceltaClienteModal(): void {
        if (this.id == 'new') {
            this.showSceltaClienteModal = true;
        }
    }

    onNuovoCliente(): void {
        const url = '/clienti/new';
        const queryParams = { from: '/ordini/new', applicatoreId: this.selectedApplicatore?.id };

        this.router.navigate([url], { queryParams: queryParams });
    }

    refreshClienti(state: ClrDatagridStateInterface): void {
        if (this.authService.isAmministratoreUser()) {
            this.refresh(state, this.clientiService, 'Cliente');
        }
    }
    
    refresh(state: ClrDatagridStateInterface, service: BaseService<AbstractBean>, entity: string): void {
        if (state && state.page) {
            if (state.page.current < 0) {
                state.page.current = 0;
            }

            const fields: Array<string> = new Array<string>();
            const operators: Array<string> = new Array<string>();
            const values: Array<any> = new Array<any>();

            if (state.filters) {
                for (const filter of state.filters) {
                    if (filter.property) {
                        fields.push(filter.property);
                        operators.push('like');
                        values.push('%' + filter.value + '%');
                    }
                }
            }
            service.getAll(
                state.page.current ? state.page.current - 1 : 0,
                state.page.size ? state.page.size : 20,
                fields,
                operators,
                values,
                state.sort ? state.sort.by.toString() : null,
                state.sort ? (state.sort.reverse ? 'desc' : 'asc') : null
            ).subscribe((result: QueryResult<AbstractBean>) => {
                this.clienti = <Array<Cliente>> result.objects;
                this.totalClienti = result.count;
            });
        }
    }

    get altezzaStandard(): boolean {
        const formValue = this.form.getRawValue();
        
        return formValue.altezzaTaraturaPressa == '' || Number(formValue.altezzaTaraturaPressa) == this.ALTEZZA_STANDARD;
    }
    
    get corsaStandard(): boolean {
        const formValue = this.form.getRawValue();
        
        return formValue.corsaPressa == '' || Number(formValue.corsaPressa) == this.CORSA_STANDARD;
    }
    
    aggiungiTest(): void {
        this.addTestField();
    }
    
    eliminaTest(i: number): void {
        let s: Observable<any> = this.tests.controls[i].getRawValue().id && this.tests.controls[i].getRawValue().id != 0 ?
            this.dialog.confirm({
                title: 'Comunicazione',
                content: 'Confermi la cancellazione?',
                acceptText: 'Sì',
                cancelText: 'Annulla',
                acceptType: 'warning',
                iconShape: null
            }) : of(true);
            
        s.subscribe((result: boolean) => {
            if (result) {
                if (this.tests.controls[i].getRawValue().id && this.tests.controls[i].getRawValue().id != 0) {
                    this.toDelete.push(this.tests.controls[i].getRawValue().id);
                }
                this.tests.removeAt(i);
                this.form.markAsDirty();
            }
        });
    }

    onFileChange(event: any) {
        this.file = event.target.files[0];
    }

    onDownloadDocumento(service: any, id: number, tipo: string) {
        service.getDocumentDownloadUrl(id.toString(), tipo).subscribe((url) => {
            if (url) {
                window.open(url);
            }
        });
    }
    
    onFileTestChange(test: FormControl, tipo: string, event: any) {
        let values = {};

        if (tipo === this.globals.DOC_MICROGRAFIA) {
            values['fileMicrografia'] = event.target.files[0];
        } else if (tipo === this.globals.DOC_CAPABILITY_ALTEZZA) {
            values['fileCapabilityAltezza'] = event.target.files[0];
        } else if (tipo === this.globals.DOC_CAPABILITY_FORZA) {
            values['fileCapabilityForza'] = event.target.files[0];
        }
        test.patchValue(values);
    }
    
    uploadFile(test: FormControl, tipo: string): void {
        let fileName = tipo + "-" + test.value.id;
        let file: File;
        
        if (tipo === this.globals.DOC_MICROGRAFIA) {
            file = test.value.fileMicrografia;
        } else if (tipo === this.globals.DOC_CAPABILITY_ALTEZZA) {
            file = test.value.fileCapabilityAltezza;
        } else if (tipo === this.globals.DOC_CAPABILITY_FORZA) {
            file = test.value.fileCapabilityForza;
        }
        this.testService.aggiungiDocumento(test.value.id, tipo, file, fileName).subscribe(() => {
            if (tipo === this.globals.DOC_MICROGRAFIA) {
                test.value.fileMicrografiaName = fileName;
            } else if (tipo === this.globals.DOC_CAPABILITY_ALTEZZA) {
                test.value.fileCapabilityAltezzaName = fileName;
            } else if (tipo === this.globals.DOC_CAPABILITY_FORZA) {
                test.value.fileCapabilityForzaName= fileName;
            }
        });
    }

    generateFile(test: FormControl, tipo: string) {
        if (tipo === this.globals.DOC_CAPABILITY_ALTEZZA) {
            this.showDialogCapabilityAltezza = true;
        } else if (tipo === this.globals.DOC_CAPABILITY_FORZA) {
            this.showDialogCapabilityForza = true;
        }
    }

    onApriApplicatore(): void {
        let queryParams = {};
        
        if (this.ordine && this.ordine.stato == StatoOrdine.EVASO) {
            queryParams['action'] = Action.VIEW;
        }
        this.router.navigate(['/applicatori/' + this.ordine.applicatore.id], {queryParams: queryParams});
    }
    
    isFormValid(): boolean {
        let valid: boolean = true;
        
        for (let el in this.form.controls) {
            if (!this.form.controls[el].disabled && !this.form.controls[el].valid) {
                valid = false;
                console.log(el);
            }
        }   
        return valid;
    }
}
