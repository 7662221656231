import {environment} from '../../environments/environment';
import {Observable, of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {BaseService} from './base.service';
import {ComponenteDefaultApplicatore} from '../model/query/componente-default-applicatore';
import {QueryResult} from '../model/query/query-result';
import {ResultUrl} from '../model/result-url';

@Injectable()
export class ComponentiDefaultApplicatoriService extends BaseService<ComponenteDefaultApplicatore> {
    private storageBasePath: string = environment.storageUrl;
    
    constructor(httpClient: HttpClient, router: Router) {
        super(httpClient, router);
        this.setApiUrl('componenti-default-applicatore');
    }
}
