export enum TipoComponente {
    SEMPLICE = 0,
    CRIMPER_RAME = 1,
    CRIMPER_GUAINA = 2,
    INCUDINE_RAME = 3,
    INCUDINE_GUAINA = 4,
    SPESSORE = 5,
    PREMI_TRANCIO_MOBILE = 6,
    SPESSORE_PREMI_TRANCIO_MOBILE = 7,
    BOCCOLA_SPESSORE_CRIMPER_GUAINA = 8,
    TRANCIO_MOBILE = 9,
    SCATOLA_TRANCIO_MOBILE = 10,
    DITO_ALIMENTAZIONE = 11,
    GUIDA_TERMINALI = 12,
    GUIDA_DITO_ALIMENTAZIONE = 13
}
