import {AbstractCommand} from './abstract-command';

export class ClienteCommand extends AbstractCommand {
    ragioneSociale: string;
    referente: string;

    constructor(ragioneSociale: string, referente: string) {
        super();
        this.ragioneSociale = ragioneSociale;
        this.referente = referente;
    }
}
