import { Component, OnInit } from '@angular/core';
import { ClrLoadingState } from '@clr/angular';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from '../../../core/globals.service';
import { ClientiService } from '../../../core/clienti.service';
import { UtilsService } from '../../../core/utils.service';
import { NazioniService } from '../../../core/nazioni.service';
import { Nazione } from '../../../model/query/nazione';
import { Cliente } from '../../../model/query/cliente';
import { Indirizzo } from '../../../model/query/indirizzo';
import { TipoIndirizzo } from '../../../model/tipo-indirizzo';
import { ClienteCommand } from '../../../model/command/cliente-command';
import { IndirizzoCommand } from '../../../model/command/indirizzo-command';
import { ClienteDTO } from '../../../model/command/cliente-dto';
import { Action } from '../../../model/action';

@Component({
    selector: 'app-cliente-detail',
    templateUrl: './cliente-detail.component.html',
    styleUrls: ['./cliente-detail.component.css']
})
export class ClienteDetailComponent implements OnInit {
    submitButton: ClrLoadingState = ClrLoadingState.DEFAULT;
    form: FormGroup;
    id: string;
    alertClosed = true;
    alertMessage: String;
    action: Action;
    readOnly = false;
    readonly TIPO_INDIRIZZO: typeof TipoIndirizzo = TipoIndirizzo;
    nazioni: Array<Nazione>;
    from: string;
    applicatoreId: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private nazioniService: NazioniService,
        private clientiService: ClientiService,
        public utilsService: UtilsService,
        private formBuilder: FormBuilder,
        private globals: Globals
    ) {
        this.createForm();
    }

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');
        this.route.queryParams.subscribe(params => {
            if (params) {
                if (params.action) {
                    this.action = params.action;
                }
                if (params.from) {
                    this.from = params.from;
                    this.applicatoreId = params.applicatoreId;
                }
            }
        });
        this.nazioniService.getList().subscribe(nazioni => {
            this.nazioni = nazioni.objects;
            if (this.id !== 'new') {
                this.clientiService.get(this.id).subscribe(entity => {
                    entity.indirizzi.forEach(i => this.addIndirizzoField());
                    this.initializeFormFields(entity);
                    if (this.action != null && this.action == Action.VIEW) {
                        this.readOnly = true;
                    }
                });
            } else {
                this.initializeFormFields(null);
            }
        });
    }

    onSubmit() {
        this.submitButton = ClrLoadingState.LOADING;
        const formValue = this.form.getRawValue();
        const dto: ClienteDTO = new ClienteDTO(formValue.ragioneSociale, formValue.referente, formValue.username, formValue.email, this.createIndirizziCommand());

        if (!this.id || this.id === 'new') {
            this.clientiService.create(dto).subscribe((id) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                if (id) {
                    this.alertMessage = 'Cliente creato!';
                    this.alertClosed = false;
                    if (this.from) {
                        setTimeout(() => {
                            this.alertClosed = true;
                            
                            const queryParams = { clienteId: id, applicatoreId: this.applicatoreId };

                            this.router.navigate([this.from], { queryParams: queryParams });
                        }, 1500);
                    } else {
                        setTimeout(() => {
                            this.alertClosed = true;
                            this.router.navigate(['clienti']);
                        }, 1500);
                    }
                }
            });
        } else {
            this.clientiService.update(this.id, dto).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                if (!res) {
                    this.alertMessage = 'Cliente aggiornato!';
                    this.alertClosed = false;
                    setTimeout(() => {
                        this.alertClosed = true;
                        this.router.navigate(['clienti']);
                    }, 1500);
                }
            });
        }
    }
    
    createIndirizziCommand(): Array<IndirizzoCommand> {
        return this.indirizzi.controls.map(control => {
            const fv = control.getRawValue();
            let ic = new IndirizzoCommand();
            
            ic.tipoIndirizzo = fv.tipoIndirizzo;
            ic.indirizzo = fv.indirizzo;
            ic.citta = fv.citta;
            ic.provincia = fv.provincia;
            ic.cap = fv.cap;
            ic.email = fv.email;
            ic.telefono = fv.telefono;
            ic.nazioneId = fv.nazione;
            return ic;
        });
    }

    createForm(): void {
        const formGroup = {
            ragioneSociale: ['', [Validators.required, Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            referente: ['', [Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            username: ['', [Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            email: ['', [Validators.maxLength(255), Validators.email]],
            indirizzi: this.formBuilder.array([])
        };
        this.form = this.formBuilder.group(formGroup);
    }

    get indirizzi() {
        return this.form.controls["indirizzi"] as FormArray;
    }

    addIndirizzoField(): void {
        const item = this.formBuilder.group({
            tipoIndirizzo: ['', Validators.required],
            indirizzo: ['', [Validators.required]],
            citta: ['', [Validators.required]],
            provincia: '',
            cap: '',
            telefono: '',
            email: '',
            nazione: ''
        });
        
        this.indirizzi.push(item);
    }

    addIndirizzo(indirizzo: Indirizzo, indirizzi: Array<any>): void {
        const indirizzoValues = {
            tipoIndirizzo: indirizzo.tipoIndirizzo.toString(),
            indirizzo: indirizzo.indirizzo,
            citta: indirizzo.citta,
            provincia: indirizzo.provincia,
            cap: indirizzo.cap,
            telefono: indirizzo.telefono,
            email: indirizzo.email,
            nazione: indirizzo.nazioneForeignKey ? indirizzo.nazioneForeignKey.toString() : ''
        };
        
        indirizzi.push(indirizzoValues);
    }

    initializeFormFields(entity: Cliente): void {
        let formValues;

        if (entity) {
            formValues = {
                ragioneSociale: entity.ragioneSociale,
                referente: entity.referente ? entity.referente : '',
                username: entity.account.username || '',
                email: entity.account.email || '',
                indirizzi: []
            };
            entity.indirizzi.forEach(indirizzo => this.addIndirizzo(indirizzo, formValues.indirizzi));
            if (entity.account.username) {
                this.form.get('username').disable();
            }
        } else {
            formValues = {
                ragioneSociale: '',
                referente: '',
                username: '',
                email: '',
                indirizzi: []
            };
        }
        this.form.setValue(formValues);
    }
    
    aggiungiIndirizzo(): void {
        this.addIndirizzoField();
    }
    
    eliminaIndirizzo(i: number): void {
        this.indirizzi.removeAt(i);
        this.form.markAsDirty();
    }
    
    isFormValid(): boolean {
        const formValue = this.form.getRawValue();
        
        return this.form.valid && ((formValue.username && formValue.email) || (!formValue.username && !formValue.email));
    }
}
