import {Component} from '@angular/core';
import {DatagridComponent} from '../common/datagrid.component';
import {ComponenteDefaultApplicatore} from '../../model/query/componente-default-applicatore';
import {Router} from '@angular/router';
import {ComponentiDefaultApplicatoriService} from '../../core/componenti-default-applicatori.service';
import {UtilsService} from '../../core/utils.service';
import {DialogService} from '../../ui/dialog';

@Component({
    selector: 'app-componenti-default-applicatore',
    templateUrl: './componenti-default-applicatore.component.html',
    styleUrls: ['./componenti-default-applicatore.component.css']
})
export class ComponentiDefaultApplicatoreComponent extends DatagridComponent<ComponenteDefaultApplicatore> {
    modelliApplicatoreDescription: any;
    tipiApplicatoreDescription: any;

    constructor(
        private componentiDefaultApplicatoriService: ComponentiDefaultApplicatoriService,
        public utilsService: UtilsService,
        dialog: DialogService,
        router: Router
    ) {
        super(componentiDefaultApplicatoriService, router, dialog);
        this.modelliApplicatoreDescription = utilsService.getModelliApplicatoreDescription();
        this.tipiApplicatoreDescription = utilsService.getTipiApplicatoreDescription();
    }

    protected getEditUrl(): string {
        return  '/componenti-default-applicatore/';
    }
}
