import {AccountCommand} from './account-command';
import {ClienteCommand} from './cliente-command';
import {IndirizzoCommand} from './indirizzo-command';

export class ClienteDTO extends AccountCommand {
    cliente: ClienteCommand;
    indirizzi: Array<IndirizzoCommand>;

    constructor(ragioneSociale: string, referente: string, username: string, email: string, indirizzi: Array<IndirizzoCommand>) {
        super(username, email);
        this.cliente = new ClienteCommand(ragioneSociale,referente);
        this.indirizzi = indirizzi;
    }
}
