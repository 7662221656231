import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { ClrDatagridStateInterface } from '@clr/angular';
import { QueryResult } from '../../../model/query/query-result';
import { AbstractBean } from '../../../model/query/abstract-bean';
import { ClrLoadingState } from '@clr/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from '../../../core/globals.service';
import { UtilsService } from '../../../core/utils.service';
import { ComponentiDefaultApplicatoriService } from '../../../core/componenti-default-applicatori.service';
import { ComponentiService } from '../../../core/componenti.service';
import { Componente } from '../../../model/query/componente';
import { ComponenteDefaultApplicatore } from '../../../model/query/componente-default-applicatore';
import { ComponenteDefaultApplicatoreCommand } from '../../../model/command/componente-default-applicatore-command';
import { Action } from '../../../model/action';

@Component({
    selector: 'app-componente-default-applicatore-detail',
    templateUrl: './componente-default-applicatore-detail.component.html',
    styleUrls: ['./componente-default-applicatore-detail.component.css']
})
export class ComponenteDefaultApplicatoreDetailComponent implements OnInit {
    modelliApplicatoreDescription: any;
    tipiApplicatoreDescription: any;
    submitButton: ClrLoadingState = ClrLoadingState.DEFAULT;
    form: FormGroup;
    selectedObjects = [];
    id: string;
    alertClosed = true;
    alertMessage: String;
    action: Action;
    readOnly = false;
    showComponenteModal: boolean = false;
    componenteModal = {
        componenteId: undefined
    };
    showPadreModal: boolean = false;
    padreModal = {
        componenteDefaultId: undefined
    };
    componenti: Array<Componente>;
    componente: Componente;
    padri: Array<ComponenteDefaultApplicatore>;
    padre: ComponenteDefaultApplicatore;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public utilsService: UtilsService,
        private componentiDefaultApplicatoriService: ComponentiDefaultApplicatoriService,
        private componentiService: ComponentiService,
        private formBuilder: FormBuilder,
        private globals: Globals
    ) {
        this.modelliApplicatoreDescription = utilsService.getModelliApplicatoreDescription();
        this.tipiApplicatoreDescription = utilsService.getTipiApplicatoreDescription();
        this.createForm();
    }

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');
        this.route.queryParams.subscribe(params => {
            if (params) {
                if (params.action) {
                    this.action = params.action;
                }
            }
        });
        if (this.id === 'new') {
            let observables: Array<Observable<any>> = [
                this.componentiService.getAll(0, 100000, [], [], [], 'nome', 'asc'),
                this.componentiDefaultApplicatoriService.getAll(0, 100000, ['modelloApplicatore', 'tipoApplicatore'], ['eq', 'eq'], [0, 0], 'componente.nome', 'asc')
            ];

            forkJoin(observables).subscribe(([componenti, padri]) => {
                this.componenti = componenti.objects;
                this.padri = padri.objects;
                this.initializeFormFields(null);
            });
        }
    }

    onSubmit() {
        this.submitButton = ClrLoadingState.LOADING;
        const formValue = this.form.getRawValue();
        const command: ComponenteDefaultApplicatoreCommand = 
            new ComponenteDefaultApplicatoreCommand(formValue.tipo, formValue.modello, this.componente.id, this.padre ? this.padre.id : null);
        
        if (this.id === 'new') {
            this.componentiDefaultApplicatoriService.create(command).subscribe((id) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                this.createForm();
                this.initializeFormFields(null);
                if (id) {
                    this.alertMessage = 'ComponenteDefaultApplicatore creato!';
                    this.alertClosed = false;
                    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                        this.router.navigate(['/componenti-default-applicatore/' + id]);
                    });
                }
            });
        }
    }

    createForm(): void {
        const formGroup = {
            modello: ['', [Validators.required]],
            tipo: ['', [Validators.required]],
            componente: [{ value: '', disabled: true }, [Validators.required]],
            padre: [{ value: '', disabled: true }]
        };
        this.form = this.formBuilder.group(formGroup);
    }

    initializeFormFields(componente: ComponenteDefaultApplicatore): void {
        let formValues;

        if (componente) {
            formValues = {
                modello: componente.modelloApplicatore.toString(),
                tipo: componente.tipoApplicatore.toString(),
                componente: componente.componente.nome + ' (' + componente.componente.codice + ')',
                padre: componente.componenteDefaultApplicatore ? componente.componenteDefaultApplicatore.componente.codice + ' (' + componente.componenteDefaultApplicatore.componente.codice + ')' : ''
            };
        } else {
            formValues = {
                modello: '0',
                tipo: '0',
                componente: '',
                padre: ''
            };
        }
        this.form.setValue(formValues);
    }
    
    onOpenComponenteDialog(): void {
        this.showComponenteModal = true;
        this.componenteModal.componenteId = '';
    }
    
    setComponente(): void {
        this.componente = this.componenti.find(c => c.id == this.componenteModal.componenteId);
        
        let formValues = {
            componente: this.componente.nome + ' (' + this.componente.codice + ')'
        };
        this.form.patchValue(formValues);
        this.showComponenteModal = false;
    }

    onOpenPadreDialog(): void {
        this.showPadreModal = true;
        this.padreModal.componenteDefaultId = '';
    }

    setPadre(): void {
        this.padre = this.padri.find(p => p.id == this.padreModal.componenteDefaultId);
        
        let formValues = {
            padre: this.padre.componente.nome + ' (' + this.padre.componente.codice + ')'
        };
        this.form.patchValue(formValues);
        this.showPadreModal = false;
    }

    onSelectChange(): void {
        const formValue = this.form.getRawValue();
        let formValues = {
            padre: ''
        };
        this.form.patchValue(formValues);
        this.componentiDefaultApplicatoriService
            .getAll(0, 100000, ['modelloApplicatore', 'tipoApplicatore'], ['eq', 'eq'], [Number(formValue.modello), Number(formValue.tipo)], 'componente.nome', 'asc')
            .subscribe(padri => this.padri = padri.objects);
    }
}
