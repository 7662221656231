import {AbstractCommand} from './abstract-command';

export class ComponenteApplicatoreCommand extends AbstractCommand {
    componenteId: number;
    applicatoreId: number;
    componenteApplicatoreId: number;
    
    constructor(componenteId: number, applicatoreId: number, componenteApplicatoreId: number) {
        super();
        this.componenteId = componenteId;
        this.applicatoreId = applicatoreId;
        this.componenteApplicatoreId = componenteApplicatoreId;
    }
}
