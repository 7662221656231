import {Component} from '@angular/core';
import {DatagridComponent} from '../common/datagrid.component';
import {Componente} from '../../model/query/componente';
import {Router} from '@angular/router';
import {ComponentiService} from '../../core/componenti.service';
import {UtilsService} from '../../core/utils.service';
import {DialogService} from '../../ui/dialog';

@Component({
    selector: 'app-componenti',
    templateUrl: './componenti.component.html',
    styleUrls: ['./componenti.component.css']
})
export class ComponentiComponent extends DatagridComponent<Componente> {
    constructor(
        private componentiService: ComponentiService,
        public utilsService: UtilsService,
        dialog: DialogService,
        router: Router
    ) {
        super(componentiService, router, dialog);
    }

    protected getEditUrl(): string {
        return  '/componenti/';
    }

    onDownloadDisegno(id: number) {
        this.componentiService.getDisegnoDownloadUrl(id.toString()).subscribe((url) => {
            if (url) {
                window.open(url);
            }
        });
    }
}
