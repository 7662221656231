import {AbstractCommand} from './abstract-command';
import {TipoApplicatore} from '../tipo-applicatore';
import {ModelloApplicatore} from '../modello-applicatore';

export class ComponenteDefaultApplicatoreCommand extends AbstractCommand {
    tipoApplicatore: TipoApplicatore;
    modelloApplicatore: ModelloApplicatore;
    componenteId: number;
    componenteDefaultApplicatoreId: number;
    
    constructor(tipoApplicatore: TipoApplicatore, modelloApplicatore: ModelloApplicatore, componenteId: number, componenteDefaultApplicatoreId: number) {
        super();
        this.componenteId = componenteId;
        this.tipoApplicatore = tipoApplicatore;
        this.modelloApplicatore = modelloApplicatore;
        this.componenteDefaultApplicatoreId = componenteDefaultApplicatoreId;
    }
}
