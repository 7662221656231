import {environment} from '../../../../environments/environment';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {Observable, of} from 'rxjs';
import { ClrDatagridStateInterface } from '@clr/angular';
import { QueryResult } from '../../../model/query/query-result';
import { AbstractBean } from '../../../model/query/abstract-bean';
import { ClrLoadingState } from '@clr/angular';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from '../../../core/globals.service';
import { UtilsService } from '../../../core/utils.service';
import {ApplicatoriService} from '../../../core/applicatori.service';
import { ComponentiService } from '../../../core/componenti.service';
import { TipoComponente } from '../../../model/tipo-componente';
import { Componente } from '../../../model/query/componente';
import { ComponenteCommand } from '../../../model/command/componente-command';
import {ComponenteApplicatoreCommand} from '../../../model/command/componente-applicatore-command';
import { Action } from '../../../model/action';

@Component({
    selector: 'app-componente-detail',
    templateUrl: './componente-detail.component.html',
    styleUrls: ['./componente-detail.component.css']
})
export class ComponenteDetailComponent implements OnInit {
    submitButton: ClrLoadingState = ClrLoadingState.DEFAULT;
    form: FormGroup;
    selectedObjects = [];
    id: string;
    alertClosed = true;
    alertMessage: String;
    action: Action;
    readOnly = false;
    fileDisegnoName: string;
    fileDisegno: File;
    imageComponentToShow: string;
    tipiComponenteDescription: any;
    showAWG: boolean = false;
    defaultImage: Object = {};
    applicatoreId: string;
    clienteId: string;
    callFrom: string;
    tipo: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public utilsService: UtilsService,
        private applicatoriService: ApplicatoriService,
        private componentiService: ComponentiService,
        private formBuilder: FormBuilder,
        private globals: Globals
    ) {
        this.createForm();
    }

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');
        this.route.queryParams.subscribe(params => {
            if (params) {
                if (params.action) {
                    this.action = params.action;
                }
                if (params.applicatoreId) {
                    this.applicatoreId = params.applicatoreId;
                    this.clienteId = params.clienteId;
                    this.callFrom = params.callFrom;
                    this.tipo = params.tipo;
                    this.tipiComponenteDescription = {};
                } else {
                    this.tipiComponenteDescription = this.utilsService.getTipiComponenteDescription();
                }
            }
        });
        this.componentiService.getDisegniDefaultUrls().subscribe((disegni) => {
            disegni.forEach(disegno => {
                this.defaultImage[disegno.tipo] = disegno.name;
            });
            if (this.id !== 'new') {
                this.componentiService.get(this.id).subscribe(componente => {
                    this.fileDisegnoName = componente.fileDisegnoName;
                    this.tipo = componente.tipoComponente.toString();
                    this.initializeFormFields(componente);
                    if (this.action != null && this.action == Action.VIEW) {
                        this.readOnly = true;
                    }
                });
            } else {
                if (!this.tipo) {
                    this.tipo = '0';
                }
                this.initializeFormFields(null);
            }
        });                    
    }

    onSubmit() {
        this.submitButton = ClrLoadingState.LOADING;
        const formValue = this.form.getRawValue();
        const command: ComponenteCommand = new ComponenteCommand(formValue.tipo,
            formValue.nome,
            formValue.descrizione,
            formValue.tipoFigura || null,
            formValue.numeroTrefoli,
            formValue.diametroTrefolo,
            formValue.larghezzaTerminale,
            formValue.spessoreTerminale,
            formValue.sezioneFilo,
            formValue.csa,
            formValue.spessore,
            formValue.passoAlette,
            formValue.lp,
            formValue.lt,
            formValue.lt2,
            formValue.ff,
            formValue.ha,
            formValue.hb,
            formValue.ht,
            formValue.h,
            formValue.sp,
            formValue.p,
            formValue.s,
            formValue.r,
            formValue.lf,
            formValue.pi,
            formValue.c,
            formValue.ri,
            formValue.hli,
            formValue.li,
            formValue.htot,
            formValue.hg);
            
        if (!this.id || this.id === 'new') {
            this.componentiService.create(command).subscribe((id) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                this.createForm();
                this.initializeFormFields(null);
                if (id) {
                    this.alertMessage = 'Componente creato!';
                    this.alertClosed = false;
                    
                    let fileName = "Disegno-" + this.id;
                    let o: Observable<any>;
                    
                    if (this.tipo == '0') {
                        o = this.componentiService.aggiungiDisegno(id.toString(), this.fileDisegno, fileName);
                    } else {
                        o = of(null);
                    }

                    o.subscribe(() => {
                        if (this.applicatoreId) {
                            let command: ComponenteApplicatoreCommand = 
                                new ComponenteApplicatoreCommand(id, Number(this.applicatoreId), null);

                            this.applicatoriService.addComponente(this.applicatoreId, command).subscribe(() => {
                                setTimeout(() => {
                                    this.alertClosed = true;

                                    const queryParams = {clienteId: this.clienteId, from: this.callFrom};

                                    this.router.navigate(['/applicatori/' + this.applicatoreId]);
                                }, 1500);
                            });
                        } else {
                            setTimeout(() => {
                                this.alertClosed = true;
                                this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                                    this.router.navigate(['/componenti/' + id]);
                                });
                            }, 1500);
                        }
                    });
                }
            });
        } else {
            this.componentiService.update(this.id, command).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                this.alertMessage = 'Componente aggiornato!';
                this.alertClosed = false;
                setTimeout(() => {
                    this.alertClosed = true;
                    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                        this.router.navigate(['/componenti/' + this.id]);
                    });
                }, 1500);
            });
        }
    }

    createForm(): void {
        const formGroup = {
            tipo: ['', [Validators.required]],
            nome: ['', [Validators.required, Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            descrizione: ['', [Validators.maxLength(255)]],
            codice: [''],
            tipoFigura: [''],
            numeroTrefoli: [''],
            diametroTrefolo: [''],
            larghezzaTerminale: [''],
            spessoreTerminale: [''],
            sezioneFilo: [''],
            csa: [''],
            spessore: [''],
            passoAlette: [''],
            lp: [''],
            lt: [''],
            lt2: [''],
            ff: [''],
            ha: [''],
            hb: [''],
            ht: [''],
            h: [''],
            sp: [''],
            p: [''],
            s: [''],
            r: [''],
            lf: [''],
            pi: [''],
            c: [''],
            ri: [''],
            hli: [''],
            li: [''],
            htot: [''],
            hg: ['']
        };
        this.form = this.formBuilder.group(formGroup);
    }

    initializeFormFields(componente: Componente): void {
        let formValues;

        if (componente) {
            formValues = {
                tipo: this.tipo,
                nome: componente.nome,
                descrizione: componente.descrizione || '',
                codice: componente.codice,
                tipoFigura: componente.tipoFigura || '',
                numeroTrefoli: componente.numeroTrefoli || '',
                diametroTrefolo: componente.diametroTrefolo || '',
                larghezzaTerminale: componente.larghezzaTerminale || '',
                spessoreTerminale: componente.spessoreTerminale || '',
                sezioneFilo: componente.sezioneFilo || '',
                csa: componente.csa || '',
                spessore: componente.spessore || '',
                passoAlette: componente.passoAlette || '',
                lp: componente.lp || '',
                lt: componente.lt || '',
                lt2: componente.lt2 || '',
                ff: componente.ff || '',
                ha: componente.ha|| '',
                hb: componente.hb || '',
                ht: componente.ht || '',
                h: componente.h || '',
                sp: componente.sp || '',
                p: componente.p || '',
                s: componente.s || '',
                r: componente.r || '',
                lf: componente.lf || '',
                pi: componente.pi || '',
                c: componente.c || '',
                ri: componente.ri || '',
                hli: componente.hli || '',
                li: componente.li || '',
                htot: componente.htot || '',
                hg: componente.hg || ''
            };
        } else {
            formValues = {
                tipo: this.tipo,
                nome: this.tipo && this.tipo != '0' ? this.tipiComponenteDescription[this.tipo] : '',
                descrizione: '',
                codice: '',
                tipoFigura: 'F',
                numeroTrefoli: '',
                diametroTrefolo: '',
                larghezzaTerminale: '',
                spessoreTerminale: '',
                sezioneFilo: '',
                csa: '',
                spessore: '',
                passoAlette: '',
                lp: '',
                lt: this.globals.DEFAULT_LT,
                lt2: '',
                ff: this.globals.DEFAULT_FF,
                ha: this.globals.DEFAULT_HA,
                hb: this.globals.DEFAULT_HB,
                ht: this.globals.DEFAULT_HT,
                h: '',
                sp: '',
                p: '',
                s: '',
                r: '',
                lf: '',
                pi: '',
                c: '',
                ri: '',
                hli: '',
                li: '',
                htot: '',
                hg: ''
            };
        }
        this.form.setValue(formValues);
        if (componente) {
            this.form.get('nome')?.disable();
            this.form.get('codice')?.disable();
            this.form.get('tipo')?.disable();
            this.form.get('tipoFigura')?.disable();
        } else if (this.applicatoreId) {
            this.form.get('tipo')?.disable();
        }
        this.setImageComponentToShow();
    }
    
    onDownloadDisegno() {
        this.componentiService.getDisegnoDownloadUrl(this.id.toString()).subscribe((url) => {
            if (url) {
                window.open(url);
            }
        });
    }

    onFileChange(event: any) {
        this.fileDisegno = event.target.files[0];
    }

    uploadFile(): void {
        let fileName = "Disegno-" + this.id;

        this.componentiService.aggiungiDisegno(this.id, this.fileDisegno, fileName).subscribe(() => {
            this.fileDisegnoName = fileName;
            this.setImageComponentToShow();
        });
    }

    setImageComponentToShow() {
        if (this.fileDisegnoName) {
            this.componentiService.getDisegnoDownloadUrl(this.id.toString()).subscribe((url) => {
                if (url) {
                    this.imageComponentToShow = url;
                }
            });
        } else {
            let tipo = this.form.getRawValue().tipo;
            
            if (tipo && tipo != '0') {
                this.imageComponentToShow = this.defaultImage[tipo];
            } else {
                this.imageComponentToShow = undefined;
            }
        }
    }
    
    onTipoChange(tipo: string): void {
        let formValues = {
            nome: tipo != '0' ? this.tipiComponenteDescription[tipo] : ''
        };

        this.tipo = tipo;
        this.form.patchValue(formValues);
        this.setImageComponentToShow();
    }
    
    onAWGChange(value: string, modal: any): void {
        let formValues = {
            sezioneFilo: value
        };

        this.form.patchValue(formValues);
        this.showAWG = false;
    }
    
    onShowAWG(): void {
        this.showAWG = true;
    }
    
    showMisure(): boolean {
        switch (this.form.getRawValue().tipo) {
            case '1':
            case '2':
            case '3':
            case '4':
            case '5':
            case '7':
            case '8':
            case '9':
            case '11':
            case '13':
                return true;
            default:
                return false;
        }
    }
}
