import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';

@Injectable()
export class CheckPathAuthorizationService {
    constructor(
        private authService: AuthService
    ) {
    }

    public isAuthorized(path: string, queryParams?: any): boolean {
        if (path.startsWith('/amministratori') || path.startsWith('/operatori') || path.startsWith('/clienti') || path.startsWith('/costruttori')
             || path.startsWith('/terminali') || path.startsWith('/presse')
             || path.startsWith('/componenti') || path.startsWith('/componenti-default-applicatore')) {
            return this.authService.isAmministratoreUser();
        } else if (path.startsWith('/applicatori')) {
            return this.authService.isAmministratoreUser() || this.authService.isClienteUser() || this.authService.isOperatoreUser();
        } else if (path.startsWith('/ordini') || path.startsWith('/etichetta-manuale')) {
            return this.authService.isAmministratoreUser() || this.authService.isOperatoreUser();
        } else {
            return true;
        }
    }
}
