import {Component, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';
import {ClrDatagridStateInterface} from '@clr/angular';
import {ClrLoadingState} from '@clr/angular';
import {QueryResult} from '../../../model/query/query-result';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {Globals} from '../../../core/globals.service';
import {AuthService} from '../../../core/auth.service';
import {ApplicatoriService} from '../../../core/applicatori.service';
import {UtilsService} from '../../../core/utils.service';
import {Applicatore} from '../../../model/query/applicatore';
import {ApplicatoreCommand} from '../../../model/command/applicatore-command';
import {TipoComponente} from '../../../model/tipo-componente';
import {Componente} from '../../../model/query/componente';
import {ComponenteApplicatore} from '../../../model/query/componente-applicatore';
import {ComponenteApplicatoreCommand} from '../../../model/command/componente-applicatore-command';
import {ComponenteCommand} from '../../../model/command/componente-command';
import {CheckMisureResponse} from '../../../model/check-misure-response';
import {TerminaliService} from '../../../core/terminali.service';
import {ComponentiService} from '../../../core/componenti.service';
import {Terminale} from '../../../model/query/terminale';
import {Action} from '../../../model/action';
import {StatoOrdine} from '../../../model/stato-ordine';
import {DialogService} from '../../dialog';

class DatagridRequest {
    state: ClrDatagridStateInterface;
    component: ApplicatoreDetailComponent;
    tipo: TipoComponente;

    constructor(state: ClrDatagridStateInterface, component: ApplicatoreDetailComponent, tipo: TipoComponente) {
        this.state = state;
        this.component = component;
        this.tipo = tipo;
    }
}

@Component({
    selector: 'app-applicatore-detail',
    templateUrl: './applicatore-detail.component.html',
    styleUrls: ['./applicatore-detail.component.css']
})

export class ApplicatoreDetailComponent implements OnInit {
    readonly TIPO_COMPONENTE: typeof TipoComponente = TipoComponente;
    readonly ACTION: typeof Action = Action;
    
    debouncerComponenti: Subject<any> = new Subject<any>();
    debouncerComponentiCustom: Subject<any> = new Subject<any>();
    subComponenti: any;
    subComponentiCustom: any;
    modelliApplicatoreDescription: any;
    tipiApplicatoreDescription: any;
    specificheAggraffaturaDescription: any;
    tipoDisposizioneDescription: any;
    tipoAggraffaturaDescription: any;
    tipiComponenteDescription: any;
    submitButton: ClrLoadingState = ClrLoadingState.DEFAULT;
    form: FormGroup;
    selectedObjects = [];
    id: string;
    alertClosed = true;
    alertMessage: String;
    action: Action;
    readOnly = false;
    applicatore: Applicatore;
    terminali: Array<Terminale>;
    componenti: Array<ComponenteApplicatore> = new Array<ComponenteApplicatore>();
    componentiParent: Array<ComponenteApplicatore> = new Array<ComponenteApplicatore>();
    selectedComponente: ComponenteApplicatore;
    totalTerminali: number;
    selectedTerminale: Terminale;
    showSceltaTerminaleModal: boolean = false;
    fileSpecificheName: string;
    fileManualeName: string;
    fileOrderFormName: string;
    fileMicrografieInterneName: string;
    fileMicrografieClienteName: string;
    fileCertificatoCollaudoName: string;
    fileSpecifiche: File;
    fileManuale: File;
    fileOrderForm: File;
    fileMicrografieInterne: File;
    fileMicrografieCliente: File;
    fileCertificatoCollaudo: File;
    showNuovoComponenteModal: boolean = false;
    nuovoComponenteModal = {
        componentiDisponibili: undefined,
        totalComponenti: undefined,
        componenteId: undefined,
        componentePadreId: undefined,
        selectedNuovoComponente: undefined
    };
    showNuovoComponenteCustomModal: boolean = false;
    nuovoComponenteCustomModal = {
        tipo: undefined,
        descrizioneTipo: undefined,
        componentiDisponibili: undefined,
        totalComponenti: undefined,
        componenteId: undefined,
        selectedNuovoComponente: undefined
    };
    premiTrancioMobile: ComponenteApplicatore;
    premiTrancioMobileSelected: boolean;
    trancioMobile: ComponenteApplicatore;
    trancioMobileSelected: boolean;
    ditoAlimentazione: ComponenteApplicatore;
    ditoAlimentazioneSelected: boolean;
    guidaDitoAlimentazione: ComponenteApplicatore;
    guidaDitoAlimentazioneSelected: boolean;
    guidaTerminali: ComponenteApplicatore;
    guidaTerminaliSelected: boolean;
    scatolaTrancioMobile: ComponenteApplicatore;
    scatolaTrancioMobileSelected: boolean;
    spessorePremiTrancioMobile: ComponenteApplicatore;
    spessorePremiTrancioMobileSelected: boolean;
    spessore: ComponenteApplicatore;
    spessoreSelected: boolean;
    boccolaSpessoreGuaina: ComponenteApplicatore;
    boccolaSpessoreGuainaSelected: boolean;
    imageComponentToShow: string;
    defaultImage: Object = {};
    crimperRame: ComponenteApplicatore;
    crimperRameSelected: boolean;
    crimperRameModal = {
        show: false,
        tipoFigura: 'C',
        diametroTrefolo: undefined,
        numeroTrefoli: undefined,
        larghezzaTerminale: undefined,
        spessoreTerminale: undefined,
        sezioneFilo: undefined,
        passoAlette: undefined,
        lt: this.globals.DEFAULT_LT,
        h: undefined,
        ha: this.globals.DEFAULT_HA,
        hb: this.globals.DEFAULT_HB,
        ht: this.globals.DEFAULT_HT,
        ff: this.globals.DEFAULT_FF,
        sp: undefined,
        componentiDisponibili: undefined,
        totalComponenti: undefined,
        selectedNuovoComponente: undefined,
        command: undefined
    };
    crimperGuaina: ComponenteApplicatore;
    crimperGuainaSelected: boolean;
    crimperGuainaModal = {
        show: false,
        tipoFigura: 'C',
        diametroTrefolo: undefined,
        numeroTrefoli: undefined,
        larghezzaTerminale: undefined,
        spessoreTerminale: undefined,
        sezioneFilo: undefined,
        passoAlette: undefined,
        lt: this.globals.DEFAULT_LT,
        h: undefined,
        ha: this.globals.DEFAULT_HA,
        hb: this.globals.DEFAULT_HB,
        ht: this.globals.DEFAULT_HT,
        ff: this.globals.DEFAULT_FF,
        sp: undefined,
        componentiDisponibili: undefined,
        totalComponenti: undefined,
        selectedNuovoComponente: undefined,
        command: undefined
    };
    incudineRame: ComponenteApplicatore;
    incudineRameSelected: boolean;
    incudineRameModal = {
        show: false,
        lf: undefined,
        h: undefined,
        ha: undefined,
        csa: undefined,
        htot: this.globals.DEFAULT_HTOT,
        hg: this.globals.DEFAULT_HG,
        componentiDisponibili: undefined,
        totalComponenti: undefined,
        selectedNuovoComponente: undefined,
        command: undefined
    };
    incudineGuaina: ComponenteApplicatore;
    incudineGuainaSelected: boolean;
    incudineGuainaModal = {
        show: false,
        lf: undefined,
        h: undefined,
        ha: undefined,
        csa: undefined,
        htot: this.globals.DEFAULT_HTOT,
        hg: this.globals.DEFAULT_HG,
        componentiDisponibili: undefined,
        totalComponenti: undefined,
        selectedNuovoComponente: undefined,
        command: undefined
    };
    from: string;
    clienteId: string;
    showAWG: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public authService: AuthService,
        private applicatoriService: ApplicatoriService,
        private terminaliService: TerminaliService,
        private componentiService: ComponentiService,
        public utilsService: UtilsService,
        private dialog: DialogService,
        private formBuilder: FormBuilder,
        private globals: Globals
    ) {
        this.modelliApplicatoreDescription = utilsService.getModelliApplicatoreDescription();
        this.tipiApplicatoreDescription = utilsService.getTipiApplicatoreDescription();
        this.specificheAggraffaturaDescription = utilsService.getSpecificheAggraffaturaDescription();
        this.tipoDisposizioneDescription = utilsService.getTipoDisposizioneDescription();
        this.tipoAggraffaturaDescription = utilsService.getTipoAggraffaturaDescription();
        this.tipiComponenteDescription = utilsService.getTipiComponenteDescription();
        this.createForm();
    }

    ngOnInit() {
        this.subComponenti = this.debouncerComponenti.asObservable().pipe(
            debounceTime(500)
        ).subscribe(this.loadComponentiDisponibili);
        this.subComponentiCustom = this.debouncerComponentiCustom.asObservable().pipe(
            debounceTime(500)
        ).subscribe(this.loadComponentiCustomDisponibili);
        this.id = this.route.snapshot.paramMap.get('id');
        this.route.queryParams.subscribe(params => {
            if (params) {
                if (params.action) {
                    this.action = params.action;
                }
                if (params.from) {
                    this.from = params.from;
                }
                if (params.terminaleId) {
                    this.terminaliService.get(params.terminaleId).subscribe(terminale => {
                        this.selectedTerminale = terminale;
                        this.initialize();
                    });
                } else {
                    this.initialize();
                }
            } else {
                this.initialize();
            }
        });
    }

    initialize() {
        this.componentiService.getDisegniDefaultUrls().subscribe((disegni) => {
            disegni.forEach(disegno => {
                this.defaultImage[disegno.tipo] = disegno.name;
            });
            if (this.id !== 'new') {
                this.applicatoriService.get(this.id).subscribe(entity => {
                    this.applicatore = entity;
                    this.fileSpecificheName = entity.fileSpecificheName;
                    this.fileManualeName = entity.fileManualeName;
                    this.fileOrderFormName = entity.fileOrderFormName;
                    this.fileMicrografieInterneName = entity.fileMicrografieInterneName;
                    this.fileMicrografieClienteName = entity.fileMicrografieClienteName;
                    this.fileCertificatoCollaudoName = entity.fileCertificatoCollaudoName;
                    if (!this.selectedTerminale) {
                        this.selectedTerminale = entity.terminale;
                    }
                    this.initializeFormFields(entity);
                    if ((this.action != null && this.action == Action.VIEW) || entity.ordine.replica) {
                        this.readOnly = true;
                        this.action = Action.VIEW;
                    }
                    this.refreshComponenti();
                });
            } else {
                this.initializeFormFields(null);
                this.refreshComponenti();
            }
        });                    
    }

    ngOnDestroy() {
        if (this.subComponenti) {
            this.subComponenti.unsubscribe();
        }
        if (this.subComponentiCustom) {
            this.subComponentiCustom.unsubscribe();
        }
    }

    refreshComponentiDisponibili(state: ClrDatagridStateInterface) {
        if (this.showNuovoComponenteModal) {
            this.debouncerComponenti.next(new DatagridRequest(state, this, TipoComponente.SEMPLICE));
        }
    }

    refreshComponentiCustomDisponibili(state: ClrDatagridStateInterface) {
        if (this.nuovoComponenteCustomModal?.tipo) {
            this.debouncerComponenti.next(new DatagridRequest(state, this, this.nuovoComponenteCustomModal.tipo));
        }
    }

    loadComponentiDisponibili(request: DatagridRequest): void {
        let component: ApplicatoreDetailComponent = request != null ? request.component : this;
        let filters: Array<any> = request != null && request.state && request.state.filters ? request.state.filters : [];
        
        if (component.nuovoComponenteModal) {
            let page: number, size: number;

            if (request == null || !request.state || !request.state.page) {
                page = 0;
                size = 10;
            } else {
                page = request.state.page.current > 0 ? request.state.page.current - 1: 0;
                size = request.state.page.size;
            }
            const fields: Array<string> = new Array<string>();
            const operators: Array<string> = new Array<string>();
            const values: Array<any> = new Array<any>();

            fields.push('tipoComponente');
            operators.push('eq');
            values.push(TipoComponente.SEMPLICE);
            component.componentiService.getAll(page, size, fields, operators, values, 'codice', 'asc').subscribe((result: QueryResult<Componente>) => {
                component.nuovoComponenteModal.componentiDisponibili = result.objects;
                component.nuovoComponenteModal.totalComponenti = result.count;
            });
        }
    }

    loadComponentiCustomDisponibili(request: DatagridRequest): void {
        let component: ApplicatoreDetailComponent = request != null ? request.component : this;
        let filters: Array<any> = request != null && request.state && request.state.filters ? request.state.filters : [];
        
        if (component.nuovoComponenteCustomModal) {
            let page: number, size: number;

            if (request == null || !request.state || !request.state.page) {
                page = 0;
                size = 10;
            } else {
                page = request.state.page.current > 0 ? request.state.page.current - 1: 0;
                size = request.state.page.size;
            }
            const fields: Array<string> = new Array<string>();
            const operators: Array<string> = new Array<string>();
            const values: Array<any> = new Array<any>();

            fields.push('tipoComponente');
            operators.push('eq');
            values.push(request?.tipo?.valueOf() || this.nuovoComponenteCustomModal.tipo);
            component.componentiService.getAll(page, size, fields, operators, values, 'codice', 'asc').subscribe((result: QueryResult<Componente>) => {
                component.nuovoComponenteCustomModal.componentiDisponibili = result.objects;
                component.nuovoComponenteCustomModal.totalComponenti = result.count;
            });
        }
    }

    refreshComponenti(): void {
        if (this.id !== 'new') {
            this.applicatoriService.getComponenti(this.id).subscribe(componenti => {
                this.crimperRame = componenti.find(c => c.componente.tipoComponente == TipoComponente.CRIMPER_RAME);
                this.crimperGuaina = componenti.find(c => c.componente.tipoComponente == TipoComponente.CRIMPER_GUAINA);
                this.incudineRame = componenti.find(c => c.componente.tipoComponente == TipoComponente.INCUDINE_RAME);
                this.incudineGuaina = componenti.find(c => c.componente.tipoComponente == TipoComponente.INCUDINE_GUAINA);
                this.premiTrancioMobile = componenti.find(c => c.componente.tipoComponente == TipoComponente.PREMI_TRANCIO_MOBILE);
                this.spessorePremiTrancioMobile = componenti.find(c => c.componente.tipoComponente == TipoComponente.SPESSORE_PREMI_TRANCIO_MOBILE);
                this.boccolaSpessoreGuaina = componenti.find(c => c.componente.tipoComponente == TipoComponente.BOCCOLA_SPESSORE_CRIMPER_GUAINA);
                this.trancioMobile = componenti.find(c => c.componente.tipoComponente == TipoComponente.TRANCIO_MOBILE);
                this.scatolaTrancioMobile  = componenti.find(c => c.componente.tipoComponente == TipoComponente.SCATOLA_TRANCIO_MOBILE);
                this.ditoAlimentazione = componenti.find(c => c.componente.tipoComponente == TipoComponente.DITO_ALIMENTAZIONE);
                this.guidaDitoAlimentazione = componenti.find(c => c.componente.tipoComponente == TipoComponente.GUIDA_DITO_ALIMENTAZIONE);
                this.guidaTerminali = componenti.find(c => c.componente.tipoComponente == TipoComponente.GUIDA_TERMINALI);
                this.componenti = componenti.filter(c => c.componente.tipoComponente == TipoComponente.SEMPLICE);
                this.componentiParent = this.componenti.filter((componente: ComponenteApplicatore) => !componente.componenteApplicatoreForeignKey);
                this.componentiParent.forEach((c: ComponenteApplicatore) => {
                    c.children = this.componenti.filter((componente: ComponenteApplicatore) => componente.componenteApplicatoreForeignKey == c.id);
                })
            });
        }
        this.imageComponentToShow = undefined;
        this.selectedComponente = undefined;
    }

    onNuovoComponente(idPadre?: number): void {
        this.showNuovoComponenteModal = true;
        this.nuovoComponenteModal.componenteId = '';
        this.nuovoComponenteModal.componentePadreId = idPadre;
        this.loadComponentiDisponibili(null);
    }

    onCreaComponente(standard: boolean): void {
        const queryParams = { applicatoreId: this.applicatore ? this.applicatore.id : 'new', clienteId: this.clienteId, callFrom: this.from, tipo: standard ? '0' : this.nuovoComponenteCustomModal.tipo};

        this.router.navigate(['/componenti/new'], { queryParams: queryParams });
    }

    addComponente(): void {
        let command: ComponenteApplicatoreCommand = 
            new ComponenteApplicatoreCommand(this.nuovoComponenteModal.selectedNuovoComponente.id, Number(this.id), this.nuovoComponenteModal.componentePadreId);

        this.applicatoriService.addComponente(this.id, command).subscribe(() => {
             this.refreshComponenti();
             this.showNuovoComponenteModal = false;
        });
    }

    onDeleteComponente(id: number): void {
        if (!this.applicatore.ordine.replica) {
            this.dialog.confirm({
                title: 'Comunicazione',
                content: 'Confermi la cancellazione?',
                acceptText: 'Sì',
                cancelText: 'Annulla',
                acceptType: 'warning',
                iconShape: null
            }).subscribe((result: boolean) => {
                if (result) {
                    this.applicatoriService.deleteComponente(this.id, id.toString()).subscribe(() => {
                        this.refreshComponenti();
                    });
                }
            });
        }
    }

    onSubmit() {
        this.submitButton = ClrLoadingState.LOADING;

        const formValue = this.form.getRawValue();
        const command: ApplicatoreCommand = new ApplicatoreCommand(formValue.codice,
            this.selectedTerminale.id,
            formValue.modello,
            formValue.tipo,
            formValue.modelloPressa,
            formValue.marcaPressa,
            formValue.altezzaTaraturaPressa,
            formValue.corsaPressa,
            formValue.specificheAggraffatura,
            formValue.contaCicli,
            formValue.ghieraCentesimale,
            formValue.taglioBandella,
            formValue.aggraffaturaGommino,
            formValue.terminaleIncudine,
            formValue.disposizioneDoppioCavo,
            formValue.aggraffaturaConduttore,
            formValue.aggraffaturaIsolante);

        if (!this.id || this.id === 'new') {
            this.applicatoriService.create(command).subscribe((id) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                if (id) {
                    this.alertMessage = 'Applicatore creato!';
                    this.alertClosed = false;
                    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                        this.router.navigate(['/applicatori/' + id]);
                    });
                }
            });
        } else {
            this.applicatoriService.update(this.id, command).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                if (!res) {
                    this.alertMessage = 'Applicatore aggiornato!';
                    this.alertClosed = false;
                    setTimeout(() => {
                        this.alertClosed = true;

                        this.router.navigate(['/ordini/' + this.applicatore.ordine.id]);
                    }, 1500);
                }
            });
        }
    }

    createForm(): void {
        const formGroup = {
            terminale: [{ value: '', disabled: true }],
            codice: [{ value: '', disabled: true }],
            modello: ['', [Validators.required]],
            tipo: ['', [Validators.required]],
            modelloPressa: ['', [Validators.required, Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            marcaPressa: ['', [Validators.required, Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            altezzaTaraturaPressa: ['', [Validators.required]],
            corsaPressa: ['', [Validators.required]],
            specificheAggraffatura: ['', [Validators.required]],
            contaCicli: [{ value: false }],
            ghieraCentesimale: [{ value: false }],
            taglioBandella: [{ value: false }],
            aggraffaturaGommino: [{ value: false }],
            terminaleIncudine: [{ value: false }],
            disposizioneDoppioCavo: ['', [Validators.required]],
            aggraffaturaConduttore: ['', [Validators.required]],
            aggraffaturaIsolante: ['', [Validators.required]]
        };
        this.form = this.formBuilder.group(formGroup);
    }

    initializeFormFields(entity: Applicatore): void {
        let formValues;

        if (entity) {
            formValues = {
                terminale: this.selectedTerminale.codice,
                codice: entity.codice,
                modello: entity.modelloApplicatore.toString(),
                tipo: entity.tipoApplicatore.toString(),
                modelloPressa: entity.modelloPressa,
                marcaPressa: entity.marcaPressa,
                altezzaTaraturaPressa: entity.altezzaTaraturaPressa,
                corsaPressa: entity.corsaPressa,
                specificheAggraffatura: entity.specificheAggraffatura.toString(),
                contaCicli: entity.contaCicli,
                ghieraCentesimale: entity.ghieraCentesimale,
                taglioBandella: entity.taglioBandella,
                aggraffaturaGommino: entity.aggraffaturaGommino,
                terminaleIncudine: entity.terminaleIncudine,
                disposizioneDoppioCavo: entity.disposizioneDoppioCavo.toString(),
                aggraffaturaConduttore: entity.aggraffaturaConduttore.toString(),
                aggraffaturaIsolante: entity.aggraffaturaIsolante.toString()
            };
        } else {
            formValues = {
                terminale: this.selectedTerminale ? this.selectedTerminale.codice : '',
                codice: '',
                modello: '0',
                tipo: '0',
                modelloPressa: '',
                marcaPressa: '',
                altezzaTaraturaPressa: '',
                corsaPressa: '',
                specificheAggraffatura: '2',
                contaCicli: false,
                ghieraCentesimale: false,
                taglioBandella: false,
                aggraffaturaGommino: false,
                terminaleIncudine: false,
                disposizioneDoppioCavo: '0',
                aggraffaturaConduttore: '0',
                aggraffaturaIsolante: '0'
            };
        }
        this.form.setValue(formValues);
    }

    onDownloadDocumento(tipo: string) {
        this.applicatoriService.getDocumentDownloadUrl(this.id.toString(), tipo).subscribe((url) => {
            if (url) {
                window.open(url);
            }
        });
    }

    onFileChange(event: any, tipo: string) {
        if (tipo === this.globals.DOC_SPECIFICHE) {
            this.fileSpecifiche = event.target.files[0];
        } else if (tipo === this.globals.DOC_MANUALE) {
            this.fileManuale = event.target.files[0];
        } else if (tipo === this.globals.DOC_ORDER_FORM) {
            this.fileOrderForm = event.target.files[0];
        } else if (tipo === this.globals.DOC_MICROGRAFIE_INTERNE) {
            this.fileMicrografieInterne = event.target.files[0];
        } else if (tipo === this.globals.DOC_MICROGRAFIE_CLIENTE) {
            this.fileMicrografieCliente = event.target.files[0];
        } else if (tipo === this.globals.DOC_CERTIFICATO_COLLAUDO) {
            this.fileCertificatoCollaudo = event.target.files[0];
        }
    }

    uploadFile(tipo: string): void {
        let fileName = tipo + "-" + this.id;
        let file: File;

        if (tipo === this.globals.DOC_SPECIFICHE) {
            file = this.fileSpecifiche;
        } else if (tipo === this.globals.DOC_MANUALE) {
            file = this.fileManuale;
        } else if (tipo === this.globals.DOC_ORDER_FORM) {
            file = this.fileOrderForm;
        } else if (tipo === this.globals.DOC_MICROGRAFIE_INTERNE) {
            file = this.fileMicrografieInterne;
        } else if (tipo === this.globals.DOC_MICROGRAFIE_CLIENTE) {
            file = this.fileMicrografieCliente;
        } else if (tipo === this.globals.DOC_CERTIFICATO_COLLAUDO) {
            file = this.fileCertificatoCollaudo;
        }
        this.applicatoriService.aggiungiDocumento(this.id, tipo, file, fileName).subscribe(() => {
            if (tipo === this.globals.DOC_SPECIFICHE) {
                this.fileSpecificheName = fileName;
            } else if (tipo === this.globals.DOC_MANUALE) {
                this.fileManualeName = fileName;
            } else if (tipo === this.globals.DOC_ORDER_FORM) {
                this.fileOrderFormName = fileName;
            } else if (tipo === this.globals.DOC_MICROGRAFIE_INTERNE) {
                this.fileMicrografieInterneName = fileName;
            } else if (tipo === this.globals.DOC_MICROGRAFIE_CLIENTE) {
                this.fileMicrografieClienteName = fileName;
            } else if (tipo === this.globals.DOC_CERTIFICATO_COLLAUDO) {
                this.fileCertificatoCollaudoName = fileName;
            }
        });
    }

    onShowSceltaTerminaleModal(): void {
        this.showSceltaTerminaleModal = true;
    }

    onSelectTerminale(): void {
        const formValues = {
            terminale: this.selectedTerminale ? this.selectedTerminale.codice : ''
        };

        this.form.patchValue(formValues);
        this.showSceltaTerminaleModal = false;
        this.form.markAsDirty();
    }
    
    onNuovoTerminale(): void {
        const url = '/terminali/new';
        const queryParams = { from: '/applicatori/' + (this.applicatore ? this.applicatore.id : 'new'), clienteId: this.clienteId, callFrom: this.from };

        this.router.navigate([url], { queryParams: queryParams });
    }

    refreshTerminali(state: ClrDatagridStateInterface): void {
        if (state && state.page && !this.authService.isClienteUser()) {
            if (state.page.current < 0) {
                state.page.current = 0;
            }

            const fields: Array<string> = new Array<string>();
            const operators: Array<string> = new Array<string>();
            const values: Array<any> = new Array<any>();

            if (state.filters) {
                for (const filter of state.filters) {
                    if (filter.property) {
                        fields.push(filter.property);
                        operators.push('like');
                        values.push('%' + filter.value + '%');
                    }
                }
            }
            this.terminaliService.getAll(
                state.page.current ? state.page.current - 1 : 0,
                state.page.size ? state.page.size : 10,
                fields,
                operators,
                values,
                state.sort ? state.sort.by.toString() : null,
                state.sort ? (state.sort.reverse ? 'desc' : 'asc') : null
            ).subscribe((result: QueryResult<Terminale>) => {
                this.terminali = <Array<Terminale>>result.objects;
                this.totalTerminali = result.count;
            });
        }
    }

    setSelectedComponente(event: any, componente: ComponenteApplicatore) {
        this.setImageComponentToShow(componente, event);
        if (event.target.checked) {
            this.selectedComponente = componente;
        } else {
            this.selectedComponente = undefined;
        }
    }

    setImageComponentToShow(componente: ComponenteApplicatore, event) {
        if (event.target.checked) {
            if (!componente.imageUrl) {
                this.componentiService.getDisegnoDownloadUrl(componente?.componente.id.toString()).subscribe((url) => {
                    if (url) {
                        componente.imageUrl = url;
                        this.imageComponentToShow = url;
                    }
                });
            } else {
                this.imageComponentToShow = componente.imageUrl;
            }
        } else {
            this.imageComponentToShow = undefined;
        }
    }
    
    setComponentePersonalizzazione(command: ComponenteCommand, modal: any, msg: string): void {
        this.componentiService.checkMisure(command).subscribe((response: any) => {
            if (!response.status) {
                if (!response.componenti || response.componenti.length == 0) {
                    modal.show = false;
                    this.dialog.confirm({
                        title: 'Comunicazione',
                        content: msg,
                        acceptText: 'Sì',
                        cancelText: 'No',
                        acceptType: 'warning',
                        iconShape: null
                    }).subscribe((result: boolean) => {
                        if (result) {
                            modal.command = command;
                            this.createNuovoComponentePersonalizzazione(modal);
                        }
                    });
                } else {
                    modal.componentiDisponibili = response.componenti;
                    modal.command = command;
                }
            }
        });
    }
    
    createNuovoComponentePersonalizzazione(modal: any): void {
        this.componentiService.create(modal.command).subscribe(id => {
            this.addComponentePersonalizzazione(id, modal);
        });
    }
    
    selectComponentePersonalizzazione(modal: any): void {
        this.addComponentePersonalizzazione(modal.selectedNuovoComponente.id, modal);
    }
    
    addComponentePersonalizzazione(componenteId: number, modal: any): void {
        let command: ComponenteApplicatoreCommand = 
            new ComponenteApplicatoreCommand(componenteId, Number(this.id), null);

        this.applicatoriService.addComponente(this.id, command).subscribe(() => {
            this.refreshComponenti();
            modal.show = false;
        });
    }
    
    addComponenteCustom(): void {
        let command: ComponenteApplicatoreCommand = 
            new ComponenteApplicatoreCommand(this.nuovoComponenteCustomModal.selectedNuovoComponente.id, Number(this.id), null);

        this.applicatoriService.addComponente(this.id, command).subscribe(() => {
            this.refreshComponenti();
            this.showNuovoComponenteCustomModal= false;
        });
    }
    
    onInserisciComponenteCustom(tipo: TipoComponente): void {
        this.nuovoComponenteCustomModal = {
            tipo: tipo,
            descrizioneTipo: this.utilsService.getTipiComponenteDescription()[tipo.valueOf()],
            componentiDisponibili: undefined,
            totalComponenti: undefined,
            componenteId: undefined,
            selectedNuovoComponente: undefined
        };
        this.showNuovoComponenteCustomModal = true;
        this.loadComponentiCustomDisponibili(null);
    }

    onInserisciCrimperRame(): void {
        this.crimperRameModal = {
            show: false,
            tipoFigura: this.crimperGuaina?.componente?.tipoFigura || 'C',
            diametroTrefolo: this.crimperGuaina?.componente?.diametroTrefolo,
            numeroTrefoli: this.crimperGuaina?.componente?.numeroTrefoli,
            larghezzaTerminale: this.crimperGuaina?.componente?.larghezzaTerminale,
            spessoreTerminale: this.crimperGuaina?.componente?.spessoreTerminale,
            sezioneFilo: this.crimperGuaina?.componente?.sezioneFilo,
            passoAlette: this.crimperGuaina?.componente?.passoAlette,
            lt: this.globals.DEFAULT_LT,
            h: '0.0',
            ha: this.globals.DEFAULT_HA,
            hb: this.globals.DEFAULT_HB,
            ht: this.globals.DEFAULT_HT,
            ff: this.globals.DEFAULT_FF,
            sp: '',
            componentiDisponibili: undefined,
            totalComponenti: undefined,
            selectedNuovoComponente: undefined,
            command: undefined
        };
        this.crimperRameModal.show = true;
    }
    
    setCrimperRame(): void {
        const command: ComponenteCommand = new ComponenteCommand(TipoComponente.CRIMPER_RAME,
            this.tipiComponenteDescription[TipoComponente.CRIMPER_RAME.valueOf()],
            '',
            this.crimperRameModal.tipoFigura,
            this.crimperRameModal.numeroTrefoli,
            this.crimperRameModal.diametroTrefolo,
            this.crimperRameModal.larghezzaTerminale,
            this.crimperRameModal.spessoreTerminale,
            this.crimperRameModal.sezioneFilo,
            undefined,
            undefined,
            this.crimperRameModal.passoAlette,
            undefined,
            this.crimperRameModal.lt,
            undefined,
            this.crimperRameModal.ff,
            this.crimperRameModal.ha,
            this.crimperRameModal.hb,
            this.crimperRameModal.ht,
            this.crimperRameModal.h,
            this.crimperRameModal.sp,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined);
        
        this.setComponentePersonalizzazione(command, this.crimperRameModal, 'Non ci sono crimper rame con le misure richieste, ne verrà creato uno nuovo, accetti?');
    }

    onInserisciCrimperGuaina(): void {
        this.crimperGuainaModal = {
            show: false,
            tipoFigura: this.crimperRame?.componente?.tipoFigura || 'C',
            diametroTrefolo: this.crimperRame?.componente?.diametroTrefolo,
            numeroTrefoli: this.crimperRame?.componente?.numeroTrefoli,
            larghezzaTerminale: this.crimperRame?.componente?.larghezzaTerminale,
            spessoreTerminale: this.crimperRame?.componente?.spessoreTerminale,
            sezioneFilo: this.crimperRame?.componente?.sezioneFilo,
            passoAlette: this.crimperRame?.componente?.passoAlette,
            lt: this.globals.DEFAULT_LT,
            h: '',
            ha: this.globals.DEFAULT_HA,
            hb: this.globals.DEFAULT_HB,
            ht: this.globals.DEFAULT_HT,
            ff: this.globals.DEFAULT_FF,
            sp: '',
            componentiDisponibili: undefined,
            totalComponenti: undefined,
            selectedNuovoComponente: undefined,
            command: undefined
        };
        this.crimperGuainaModal.show = true;
    }
    
    setCrimperGuaina(): void {
        const command: ComponenteCommand = new ComponenteCommand(TipoComponente.CRIMPER_GUAINA,
            this.tipiComponenteDescription[TipoComponente.CRIMPER_GUAINA.valueOf()],
            '',
            this.crimperGuainaModal.tipoFigura,
            this.crimperGuainaModal.numeroTrefoli,
            this.crimperGuainaModal.diametroTrefolo,
            this.crimperGuainaModal.larghezzaTerminale,
            this.crimperGuainaModal.spessoreTerminale,
            this.crimperGuainaModal.sezioneFilo,
            undefined,
            undefined,
            this.crimperRameModal.passoAlette,
            undefined,
            this.crimperGuainaModal.lt,
            undefined,
            this.crimperGuainaModal.ff,
            this.crimperRameModal.ha,
            this.crimperRameModal.hb,
            this.crimperGuainaModal.ht,
            this.crimperGuainaModal.h,
            this.crimperGuainaModal.sp,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined);
        
        this.setComponentePersonalizzazione(command, this.crimperGuainaModal, 'Non ci sono crimper guaina con le misure richieste, ne verrà creato uno nuovo, accetti?');
    }

    onInserisciIncudineRame(): void {
        if (!this.crimperRame) {
            this.dialog.info({
                title: 'Info',
                content: 'Inserisci prima il crimper rame',
            }).subscribe();
        } else {
            this.incudineRameModal = {
                show: false,
                lf: this.incudineGuaina?.componente?.lf || this.crimperRame?.componente?.lf || '',
                h: this.incudineGuaina?.componente?.h || this.crimperRame?.componente?.h || '0.0',
                ha: this.incudineGuaina?.componente?.ha || this.crimperRame?.componente?.ha || '',
                csa: this.incudineGuaina?.componente?.csa || this.crimperRame?.componente?.csa || '',
                htot: this.incudineGuaina?.componente?.htot || this.globals.DEFAULT_HTOT,
                hg: this.incudineGuaina?.componente?.hg || this.globals.DEFAULT_HG,
                componentiDisponibili: undefined,
                totalComponenti: undefined,
                selectedNuovoComponente: undefined,
                command: undefined
            };
            this.incudineRameModal.show = true;
        }
    }
    
    setIncudineRame(): void {
        const command: ComponenteCommand = new ComponenteCommand(TipoComponente.INCUDINE_RAME,
            this.tipiComponenteDescription[TipoComponente.INCUDINE_RAME.valueOf()],
            '',
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            this.incudineRameModal.csa,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            this.incudineRameModal.ha,
            undefined,
            undefined,
            this.incudineRameModal.h,
            undefined,
            undefined,
            undefined,
            undefined,
            this.incudineRameModal.lf,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            this.incudineRameModal.htot,
            this.incudineRameModal.hg);
        
        this.setComponentePersonalizzazione(command, this.incudineRameModal, 'Non ci sono incudini rame con le misure richieste, ne verrà creata una nuova, accetti?');
    }

    onInserisciIncudineGuaina(): void {
        if (!this.crimperGuaina) {
            this.dialog.info({
                title: 'Info',
                content: 'Inserisci prima il crimper guaina',
            }).subscribe();
        } else {
            this.incudineGuainaModal = {
                show: false,
                lf: this.incudineRame?.componente?.lf || this.crimperGuaina?.componente?.lf || '',
                h: this.incudineRame?.componente?.h || this.crimperGuaina?.componente?.h || '0.0',
                ha: this.incudineRame?.componente?.ha || this.crimperGuaina?.componente?.ha || '',
                csa: this.incudineRame?.componente?.csa || this.crimperGuaina?.componente?.csa || '',
                htot: this.incudineRame?.componente?.htot || this.globals.DEFAULT_HTOT,
                hg: this.incudineRame?.componente?.hg || this.globals.DEFAULT_HG,
                componentiDisponibili: undefined,
                totalComponenti: undefined,
                selectedNuovoComponente: undefined,
                command: undefined
            };
            this.incudineGuainaModal.show = true;
        }
    }
    
    setIncudineGuaina(): void {
        const command: ComponenteCommand = new ComponenteCommand(TipoComponente.INCUDINE_GUAINA,
            this.tipiComponenteDescription[TipoComponente.INCUDINE_GUAINA.valueOf()],
            '',
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            this.incudineRameModal.csa,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            this.incudineRameModal.ha,
            undefined,
            undefined,
            this.incudineRameModal.h,
            undefined,
            undefined,
            undefined,
            undefined,
            this.incudineGuainaModal.lf,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            this.incudineRameModal.htot,
            this.incudineRameModal.hg);
        
        this.setComponentePersonalizzazione(command, this.incudineGuainaModal, 'Non ci sono incudini guaina con le misure richieste, ne verrà creata una nuova, accetti?');
    }
    
    apriOrdine(): void {
        this.router.navigate(['/ordini/' + this.applicatore.ordine.id]);
    }
    
    backToOrdine(): void {
        let queryParams = {
            applicatoreId: this.applicatore.id
        };
        
        this.router.navigate(['/ordini/new'], {queryParams: queryParams});
    }
    
    onAWGCrimperRameChange(value: string, modal: any): void {
        this.crimperRameModal.sezioneFilo = value;
        this.showAWG = false;
    }
    
    onAWGCrimperGuainaChange(value: string, modal: any): void {
        this.crimperGuainaModal.sezioneFilo = value;
        this.showAWG = false;
    }
    
    onShowAWG(): void {
        this.showAWG = true;
    }

    onNuovoComponenteStandard(): void {
        const url = '/componenti/new';
        const queryParams = { from: '/applicatori/' + (this.applicatore ? this.applicatore.id : 'new'), clienteId: this.clienteId, callFrom: this.from };

        this.router.navigate([url], { queryParams: queryParams });
    }
}
