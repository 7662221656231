import {environment} from '../../environments/environment';
import {Observable, of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {BaseService} from './base.service';
import {Componente} from '../model/query/componente';
import {ComponenteCommand} from '../model/command/componente-command';
import {ResultUrl} from '../model/result-url';
import {CheckMisureResponse} from '../model/check-misure-response';
import {DisegnoDefaultName} from '../model/disegno-default-name';

@Injectable()
export class ComponentiService extends BaseService<Componente> {
    private storageBasePath: string = environment.storageUrl;

    constructor(httpClient: HttpClient, router: Router) {
        super(httpClient, router);
        this.setApiUrl('componenti');
    }

    aggiungiDisegno(id: string, file: File, fileName: string): Observable<number> {
        return this.httpClient.post(this.apiUrl + '/' + id + '/disegno', {fileName: fileName}, {observe: 'response'}).pipe(
            switchMap((res: HttpResponse<any>): Observable<any> => {
                return this.httpClient.get<Componente>(this.apiUrl + '/' + id);
            }),
            switchMap((componente: Componente) => {
                if (componente != null) {
                    return this.httpClient.put(this.storageBasePath + componente.fileDisegnoUrl, file);
                } else {
                    return of(null);
                }
            }),
            switchMap(() => {
                return of(id);
            }),
            catchError(this.handleError(' - aggiungiDisegno', null))
        );
    }

    getDisegnoDownloadUrl(id: string): Observable<string> {
        return this.httpClient.get<ResultUrl>(this.apiUrl + '/' + id + '/disegno-download-url').pipe(
            switchMap((result: ResultUrl) => {
                return of(this.storageBasePath + result.url);
            }),
            catchError(this.handleError(this.apiUrl + ' - getDisegnoDownloadUrl', null))
        );
    }

    getDisegniDefaultUrls(): Observable<Array<DisegnoDefaultName>> {
        return this.httpClient.get<Array<DisegnoDefaultName>>(this.apiUrl + '/disegni-default-urls/').pipe(
            switchMap((result: Array<DisegnoDefaultName>) => {
                return of(result.map(d => {
                    d.name = this.storageBasePath + d.name;
                    return d;
                }));
            }),
            catchError(this.handleError(this.apiUrl + ' - getDisegniDefaultUrls', null))
        );
    }

    checkMisure(command: ComponenteCommand): Observable<CheckMisureResponse> {
        return this.httpClient.post(this.apiUrl + '/check-misure', command).pipe(
            catchError(this.handleError(this.apiUrl + ' - check-misure', null))
        );
    }
}
