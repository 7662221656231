import { Component, OnInit } from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';
import { ClrDatagridStateInterface } from '@clr/angular';
import { QueryResult } from '../../../model/query/query-result';
import { AbstractBean } from '../../../model/query/abstract-bean';
import { ClrLoadingState } from '@clr/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from '../../../core/globals.service';
import { TerminaliService } from '../../../core/terminali.service';
import { CostruttoriService } from '../../../core/costruttori.service';
import { OrdiniService } from '../../../core/ordini.service';
import { ApplicatoriService } from '../../../core/applicatori.service';
import { Costruttore } from '../../../model/query/costruttore';
import { Ordine } from '../../../model/query/ordine';
import { Terminale } from '../../../model/query/terminale';
import { ClienteCodiceTerminale } from '../../../model/query/cliente-codice-terminale';
import { TerminaleCommand } from '../../../model/command/terminale-command';
import { Action } from '../../../model/action';
import {DialogService} from '../../dialog';

@Component({
    selector: 'app-terminale-detail',
    templateUrl: './terminale-detail.component.html',
    styleUrls: ['./terminale-detail.component.css']
})
export class TerminaleDetailComponent implements OnInit {
    submitButton: ClrLoadingState = ClrLoadingState.DEFAULT;
    form: FormGroup;
    selectedObjects = [];
    id: string;
    alertClosed = true;
    alertMessage: String;
    action: Action;
    readOnly = false;
    costruttori: Array<Costruttore>;
    codiciInterni: Array<ClienteCodiceTerminale>;
    clienti: Array<string> = new Array<string>();
    totalCodiciInterni: number;
    fileSpecificheName: string;
    fileSpecifiche: File;
    codice: string;
    ordini: Array<Ordine> = new Array<Ordine>();
    totalOrdini: number = 0;
    from: string;
    clienteId: string;
    callFrom: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private terminaliService: TerminaliService,
        private costruttoriService: CostruttoriService,
        private ordiniService: OrdiniService,
        private applicatoriService: ApplicatoriService,
        private dialog: DialogService,
        private formBuilder: FormBuilder,
        private globals: Globals
    ) {
        this.createForm();
    }

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');
        this.route.queryParams.subscribe(params => {
            if (params) {
                if (params.action) {
                    this.action = params.action;
                }
                if (params.from) {
                    this.from = params.from;
                    this.clienteId = params.clienteId;
                    this.callFrom = params.callFrom;
                }
            }
        });
        this.costruttoriService.getList().subscribe(costruttori => {
            this.costruttori = costruttori.objects;
            if (this.id !== 'new') {
                let observables: Array<Observable<any>> = [
                    this.terminaliService.get(this.id),
                    this.terminaliService.getClienti(this.id)
                ];

                forkJoin(observables).subscribe(([entity, clienti]) => {
                    this.codice = entity.codice;
                    this.fileSpecificheName = entity.fileSpecificheName;
                    this.clienti = clienti;
                    this.initializeFormFields(entity);
                    if (this.action != null && this.action == Action.VIEW) {
                        this.readOnly = true;
                    }
                });
            } else {
                this.initializeFormFields(null);
            }
        });
    }

    onSubmit() {
        this.submitButton = ClrLoadingState.LOADING;
        const formValue = this.form.getRawValue();
        const command: TerminaleCommand = new TerminaleCommand(formValue.codice, formValue.costruttore);
        if (!this.id || this.id === 'new') {
            this.terminaliService.create(command).subscribe((id) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                this.createForm();
                this.initializeFormFields(null);
                if (id) {
                    this.alertMessage = 'Terminale creato!';
                    this.alertClosed = false;
                    if (this.from) {
                        setTimeout(() => {
                            this.alertClosed = true;
                            
                            const queryParams = { terminaleId: id, clienteId: this.clienteId, from: this.callFrom };

                            this.router.navigate([this.from], { queryParams: queryParams });
                        }, 1500);
                    }
                }
            });
        } else {
            this.terminaliService.update(this.id, command).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                this.alertMessage = 'Terminale aggiornato!';
                this.alertClosed = false;
            });
        }
    }

    createForm(): void {
        const formGroup = {
            codice: ['', [Validators.required, Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            costruttore: ''
        };
        this.form = this.formBuilder.group(formGroup);
    }

    initializeFormFields(entity: Terminale): void {
        let formValues;

        if (entity) {
            formValues = {
                codice: entity.codice,
                costruttore: entity.costruttoreForeignKey ? entity.costruttoreForeignKey.toString() : ''
            };
        } else {
            formValues = {
                codice: '',
                costruttore: ''
            };
        }
        this.form.setValue(formValues);
    }

    onDownloadDocumento() {
        this.terminaliService.getDocumentDownloadUrl(this.id.toString(), this.globals.DOC_SPECIFICHE).subscribe((url) => {
            if (url) {
                window.open(url);
            }
        });
    }

    onFileChange(event: any, tipo: string) {
        if (tipo === this.globals.DOC_SPECIFICHE) {
            this.fileSpecifiche = event.target.files[0];
        }
    }

    uploadFile(): void {
        let fileName = "Specifiche-" + this.id;

        this.terminaliService.aggiungiDocumento(this.id, this.globals.DOC_SPECIFICHE, this.fileSpecifiche, fileName).subscribe(() => {
            this.fileSpecificheName = fileName;
        });
    }

    refreshOrdini(state: ClrDatagridStateInterface): void {
        if (state && state.page) {
            if (state.page.current < 0) {
                state.page.current = 0;
            }

            const fields: Array<string> = new Array<string>();
            const operators: Array<string> = new Array<string>();
            const values: Array<any> = new Array<any>();

            fields.push('applicatore.terminale.codice');
            operators.push('eq');
            values.push(this.codice);
            this.ordiniService.getAll(state.page.current ? state.page.current - 1 : 0,
                state.page.size ? state.page.size : 10,
                fields, operators, values, null, null).subscribe((result: QueryResult<Ordine>) => {
                    this.ordini = result.objects;
                    this.totalOrdini = result.count;
                });
        }
    }

    refreshCodiciInterni(state: ClrDatagridStateInterface): void {
        if (state && state.page) {
            if (state.page.current < 0) {
                state.page.current = 0;
            }

            const fields: Array<string> = new Array<string>();
            const operators: Array<string> = new Array<string>();
            const values: Array<any> = new Array<any>();

            if (state.filters) {
                for (const filter of state.filters) {
                    if (filter.property) {
                        fields.push(filter.property);
                        operators.push('like');
                        values.push('%' + filter.value + '%');
                    }
                }
            }
            this.terminaliService.getCodiciInterni(this.id,
                state.page.current ? state.page.current - 1 : 0,
                state.page.size ? state.page.size : 10,
                fields,
                operators,
                values,
                state.sort ? state.sort.by.toString() : null,
                state.sort ? (state.sort.reverse ? 'desc' : 'asc') : null
            ).subscribe((result: QueryResult<AbstractBean>) => {
                this.codiciInterni = <Array<ClienteCodiceTerminale>>result.objects;
                this.totalCodiciInterni = result.count;
            });
        }
    }

    onApriApplicatore(id: number): void {
        this.router.navigate(['/applicatori/' + id]);
    }
}
