import {Component, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import {ApplicatoriService} from '../../core/applicatori.service';
import {EtichettaManualeDTO} from '../../model/command/etichetta-manuale-dto';
import {PosizioneGhieraCommand} from '../../model/command/posizione-ghiera-command';

@Component({
    selector: 'app-etichetta-manuale',
    templateUrl: './etichetta-manuale.component.html',
    styleUrls: ['./etichetta-manuale.component.css']
})
export class EtichettaManualeComponent implements OnInit {
    form: FormGroup;

    constructor(
        private applicatoriService: ApplicatoriService,
        private formBuilder: FormBuilder
    ) {
    }

    ngOnInit() {
        this.createForm();
        this.initializeFormFields();
    }

    createPosizioniGhieraCommand(): Array<PosizioneGhieraCommand> {
        return this.posizioniGhiera.controls.map((control, index) => {
            const fv = control.getRawValue();
            let ic = new PosizioneGhieraCommand();
            
            ic.indice = index;
            ic.posizione = fv.posizione;
            ic.sezione = Number(fv.sezione);
            ic.wh = Number(fv.wh);
            ic.ih = Number(fv.ih);
            return ic;
        });
    }
    
    onDownloadEtichetta(): void {
        let dto: EtichettaManualeDTO = new EtichettaManualeDTO();
        const formValue = this.form.getRawValue();
        
        dto.applicatore = formValue.applicatore;
        dto.terminale = formValue.terminale;
        dto.descrizione = formValue.descrizione;
        dto.posizioniGhiera = this.createPosizioniGhieraCommand();
        this.applicatoriService.getEtichettaManualeDownloadLink(dto).subscribe((url) => {
            if (url) {
                window.open(url);
            }
        });
    }

    createForm(): void {
        const formGroup = {
            applicatore: ['', Validators.required],
            terminale: ['', Validators.required],
            descrizione: [{value: ''}],
            posizioniGhiera: this.formBuilder.array([])
        };
        this.form = this.formBuilder.group(formGroup);
        for (let i = 0; i < 4; ++i) {
            this.addPosizioneGhieraField();
        }
    }

    get posizioniGhiera() {
        return this.form.controls["posizioniGhiera"] as FormArray;
    }
    
    addPosizioneGhieraField(): void {
        const item = this.formBuilder.group({
            posizione: ['', Validators.required],
            sezione: ['', Validators.required],
            wh: ['', Validators.required],
            ih: ['', Validators.required]
        });
        
        this.posizioniGhiera.push(item);
    }

    addPosizioneGhiera(posizione: any, posizioniGhiera: Array<any>): void {
        const posizioniValues = {
            posizione: posizione.posizione || '',
            sezione: posizione.sezione || '',
            wh: posizione.wh || '',
            ih: posizione.ih || ''
        };
        
        posizioniGhiera.push(posizioniValues);
    }

    initializeFormFields(): void {
        let formValues = {
            applicatore: '',
            terminale: '',
            descrizione: '',
            posizioniGhiera: []
        };
        
        for (let i = 0; i < 4; ++i) {
            this.addPosizioneGhiera({}, formValues.posizioniGhiera)
        }
        this.form.setValue(formValues);
    }
}
