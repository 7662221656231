import {from as observableFrom, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';

declare let Keycloak: any;

@Injectable()
export class AuthService {
    private static auth: any = {};
    private static roleName: any = {
        'amministratore': 'Amministratore',
        'operatore': 'Operatore',
        'cliente': 'Cliente'
    };
    private availableRoles = Object.keys(AuthService.roleName);

    static init(): Promise<any> {
        const keycloakAuth: any = Keycloak({
            url: environment.keycloakRootUrl,
            realm: 'cablex',
            clientId: 'cablex',
            'ssl-required': 'none',
            'public-client': true,
            responseMode: 'fragment',
            flow: 'standard'
        });

        AuthService.auth.loggedIn = false;

        return new Promise((resolve, reject) => {
            keycloakAuth.init({onLoad: 'login-required', checkLoginIframe: false})
                .success(() => {
                    AuthService.auth.loggedIn = true;
                    AuthService.auth.authz = keycloakAuth;
                    AuthService.auth.logoutUrl = keycloakAuth.authServerUrl
                        + '/realms/cablex/protocol/openid-connect/logout?redirect_uri='
                        + encodeURI(document.baseURI);
                    resolve(AuthService.auth);
                })
                .error(() => {
                    reject();
                });
        });
    }

    public constructor() {

    }

    public getLogoutUrl(): string {
        return AuthService.auth.logoutUrl;
    }

    public getUserId(): string {
        return AuthService.auth.authz.tokenParsed.sub;
    }

    public getUsername(): string {
        return AuthService.auth.authz.tokenParsed.preferred_username;
    }

    public getFullName(): string {
        return AuthService.auth.authz.tokenParsed.name;
    }

    public getFirstName(): string {
        return AuthService.auth.authz.tokenParsed.given_name;
    }

    public setFirstName(firstName: string): void {
        AuthService.auth.authz.tokenParsed.given_name = firstName;
    }

    public getLastName(): string {
        return AuthService.auth.authz.tokenParsed.family_name;
    }

    public setLastName(lastName: string): void {
        AuthService.auth.authz.tokenParsed.family_name = lastName;
    }

    public getEmail(): string {
        return AuthService.auth.authz.tokenParsed.email;
    }

    public setEmail(email: string): void {
        AuthService.auth.authz.tokenParsed.email = email;
    }

    public isAmministratoreUser(): boolean {
        return AuthService.auth.authz.tokenParsed.realm_access.roles.indexOf('amministratore') > -1;
    }

    public isOperatoreUser(): boolean {
        return AuthService.auth.authz.tokenParsed.realm_access.roles.indexOf('operatore') > -1;
    }

    public isClienteUser(): boolean {
        return AuthService.auth.authz.tokenParsed.realm_access.roles.indexOf('cliente') > -1;
    }

    public getRole(): string {
        return AuthService.auth.authz.tokenParsed.realm_access.roles.find(r => r != 'offline_access' && r != 'uma_authorization' && r != 'default-roles-cablex');
    }

    public getRoleName(): string {
        return AuthService.roleName[this.getRole()];
    }

    public getToken(minValidity: number): Observable<any> {
        const promise = new Promise((resolve, reject) => {
            if (AuthService.auth.authz && AuthService.auth.authz.token) {
                AuthService.auth.authz.updateToken(minValidity)
                    .success(() => {
                        resolve(<string>AuthService.auth.authz.token);
                    })
                    .error(() => {
                        reject('Failed to refresh token');
                    });
            } else {
                reject('Not logged in');
            }
        });

        return observableFrom(promise);
    }

}
