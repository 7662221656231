import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AmministratoreDetailComponent} from './ui/amministratori/amministratore-detail/amministratore-detail.component';
import {AmministratoriComponent} from './ui/amministratori/amministratori.component';
import {OperatoriComponent} from './ui/operatori/operatori.component';
import {OperatoreDetailComponent} from './ui/operatori/operatore-detail/operatore-detail.component';
import {ClientiComponent} from './ui/clienti/clienti.component';
import {ClienteDetailComponent} from './ui/clienti/cliente-detail/cliente-detail.component';
import {CostruttoriComponent} from './ui/costruttori/costruttori.component';
import {CostruttoreDetailComponent} from './ui/costruttori/costruttore-detail/costruttore-detail.component';
import {ApplicatoriComponent} from './ui/applicatori/applicatori.component';
import {ApplicatoreDetailComponent} from './ui/applicatori/applicatore-detail/applicatore-detail.component';
import {TerminaliComponent} from './ui/terminali/terminali.component';
import {TerminaleDetailComponent} from './ui/terminali/terminale-detail/terminale-detail.component';
import {OrdiniComponent} from './ui/ordini/ordini.component';
import {OrdineDetailComponent} from './ui/ordini/ordine-detail/ordine-detail.component';
import {ComponentiDefaultApplicatoreComponent} from './ui/componenti-default-applicatore/componenti-default-applicatore.component';
import {ComponenteDefaultApplicatoreDetailComponent} from './ui/componenti-default-applicatore/componente-default-applicatore-detail/componente-default-applicatore-detail.component';
import {ComponentiComponent} from './ui/componenti/componenti.component';
import {ComponenteDetailComponent} from './ui/componenti/componente-detail/componente-detail.component';
import {EtichettaManualeComponent} from './ui/etichetta-manuale/etichetta-manuale.component';
import {ErrorComponent} from './error.component';
import {PasswordComponent} from './ui/password/password.component';
import {AuthGuard} from './core/auth.guard';

const routes: Routes = [
    {
        path: 'amministratori/:id',
        component: AmministratoreDetailComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'amministratori',
        component: AmministratoriComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'operatori',
        component: OperatoriComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'operatori/:id',
        component: OperatoreDetailComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'clienti',
        component: ClientiComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'clienti/:id',
        component: ClienteDetailComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'costruttori',
        component: CostruttoriComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'costruttori/:id',
        component: CostruttoreDetailComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'applicatori',
        component: ApplicatoriComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'applicatori/:id',
        component: ApplicatoreDetailComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'terminali',
        component: TerminaliComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'terminali/:id',
        component: TerminaleDetailComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'ordini',
        component: OrdiniComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'ordini/:id',
        component: OrdineDetailComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'componenti-default-applicatore',
        component: ComponentiDefaultApplicatoreComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'componenti-default-applicatore/:id',
        component: ComponenteDefaultApplicatoreDetailComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'componenti',
        component: ComponentiComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'componenti/:id',
        component: ComponenteDetailComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'etichetta-manuale',
        component: EtichettaManualeComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'error',
        component: ErrorComponent
    },
    {
        path: 'password',
        component: PasswordComponent
    },
    {path: '', redirectTo: '/', pathMatch: 'full'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
