import {AbstractCommand} from './abstract-command';
import {TipoApplicatore} from '../tipo-applicatore';
import {ModelloApplicatore} from '../modello-applicatore';
import {SpecificheAggraffatura} from '../specifiche-aggraffatura';
import {TipoDisposizione} from '../tipo-disposizione';
import {TipoAggraffatura} from '../tipo-aggraffatura';
import {MaterialeOrdine} from '../materiale-ordine';
import {StatoOrdine} from '../stato-ordine';

export class OrdineCommand extends AbstractCommand {
    stato: StatoOrdine;
    clienteId: number;
    applicatoreId: number;
    dataEmissione: Date;
    numeroOrdine: string;
    referente: string;
    costruttore: string;
    codiceInternoCliente: string;
    nuovo: boolean;
    replica: boolean;
    note: string;
    materialeOrdine: MaterialeOrdine;

    constructor(stato: StatoOrdine,
        clienteId: number,
        applicatoreId: number,
        dataEmissione: Date,
        numeroOrdine: string,
        referente: string,
        costruttore: string,
        codiceInternoCliente: string,
        nuovo: boolean,
        replica: boolean,
        note: string,
        materialeOrdine: MaterialeOrdine) {
        super();
        this.stato = stato;
        this.clienteId = clienteId;
        this.applicatoreId = applicatoreId;
        this.dataEmissione = dataEmissione;
        this.numeroOrdine = numeroOrdine;
        this.referente = referente;
        this.costruttore = costruttore;
        this.codiceInternoCliente = codiceInternoCliente;
        this.nuovo = nuovo;
        this.replica = replica;
        this.note = note;
        this.materialeOrdine = materialeOrdine;
    }
}
